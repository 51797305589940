// src/services/apiService.js
import axios from 'axios';
import { AuthContext } from '../context/authContext';
import { useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import handleApiError from './errorHandling';
import { toast } from 'sonner';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const useApiService = () => {
  const { token } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const apiService = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  });

  // Add these default options for all queries
  const defaultQueryOptions = {
    // refetchInterval: 5000, // Refetch every 5 seconds
    // refetchOnWindowFocus: true,
    // refetchOnMount: true,
    // staleTime: 0, // Consider data stale immediately
  };

  // token required

  // helper routes
  const findScore = (data) => apiService.get('/find_score', { params: data });
  const appCoachCount = () => apiService.get('/app_coach_count');
  const appAthleteCount = () => apiService.get('/app_athlete_count');
  const appAthletePrCount = () => apiService.get('/app_athlete_pr_count');
  const appAthleteWorkoutsCount = () => apiService.get('/app_athlete_workouts_count');
  const weather = (data) => apiService.get('/weather', { params: data });

  // stripe
  const createSubscription = (data) => apiService.post('/subscriptions', data);

  // users
  const useUsers = () => useQuery(['users'], () => apiService.get('/users').then(res => res.data), defaultQueryOptions);
  const useUser = (id) => useQuery(['user', id], () => apiService.get(`/users/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateUser = () => useMutation(
    (data) => apiService.post('/users', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        toast.success('User created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    },
  );
  const useUpdateUser = () => useMutation(
    ({ id, data }) => apiService.put(`/users/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        toast.success('User updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteUser = () => useMutation(
    (id) => apiService.delete(`/users/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        toast.success('User deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // event_groups
  const useEventGroups = () => useQuery(['eventGroups'], () => apiService.get('/event_groups').then(res => res.data), defaultQueryOptions);
  const useEventGroup = (id) => useQuery(['eventGroup', id], () => apiService.get(`/event_groups/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateEventGroup = () => useMutation(
    (data) => apiService.post('/event_groups', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['eventGroups']);
        queryClient.invalidateQueries(['athletes']);
        toast.success('Event group created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateEventGroup = () => useMutation(
    ({ id, data }) => apiService.put(`/event_groups/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['eventGroups']);
        queryClient.invalidateQueries(['athletes']);
        toast.success('Event group updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteEventGroup = () => useMutation(
    (id) => apiService.delete(`/event_groups/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['eventGroups']);
        queryClient.invalidateQueries(['athletes']);
        toast.success('Event group deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // athletes
  const useAthletes = () => useQuery(['athletes'], () => apiService.get('/athletes').then(res => res.data), defaultQueryOptions);
  const useAthlete = (id) => useQuery(['athlete', id], () => apiService.get(`/athletes/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateAthlete = () => useMutation(
    (data) => apiService.post('/athletes', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['athletes']);
        toast.success('Athlete created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateAthlete = () => useMutation(
    ({ id, data }) => apiService.put(`/athletes/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athletes']);
        queryClient.invalidateQueries(['eventGroups']);
        toast.success('Athlete updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteAthlete = () => useMutation(
    (id) => apiService.delete(`/athletes/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athletes']);
        queryClient.invalidateQueries(['eventGroups']);
        toast.success('Athlete removed successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useLinkEventGroups = () => useMutation(
    ({ id, data }) => apiService.post(`/athletes/${id}/link_event_groups`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athletes']);
        queryClient.invalidateQueries(['eventGroups']);
        toast.success('Event groups linked successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUnlinkEventGroups = () => useMutation(
    ({ id, data }) => apiService.post(`/athletes/${id}/unlink_event_groups`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athletes']);
        queryClient.invalidateQueries(['eventGroups']);
        toast.success('Event groups unlinked successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // workouts
  const useWorkouts = () => useQuery(['workouts'], () => apiService.get('/workouts').then(res => res.data), defaultQueryOptions);
  const useWorkout = (id) => useQuery(['workout', id], () => apiService.get(`/workouts/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateWorkout = () => useMutation(
    (data) => apiService.post('/workouts', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workouts']);
        toast.success('Workout created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateWorkout = () => useMutation(
    ({ id, data }) => apiService.put(`/workouts/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workouts']);
        toast.success('Workout updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteWorkout = () => useMutation(
    (id) => apiService.delete(`/workouts/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workouts']);
        toast.success('Workout deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // workout_sets
  const useWorkoutSets = (workoutId) => useQuery(['workoutSets', workoutId], () => apiService.get(`/workouts/${workoutId}/workout-sets`).then(res => res.data), defaultQueryOptions);
  const useWorkoutSet = (workoutId, id) => useQuery(['workoutSet', workoutId, id], () => apiService.get(`/workouts/${workoutId}/workout-sets/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateWorkoutSet = (workoutId) => useMutation(
    (data) => apiService.post(`/workouts/${workoutId}/workout-sets`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workoutSets', workoutId]);
        queryClient.invalidateQueries(['workouts']);
        toast.success('Workout set created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateWorkoutSet = (workoutId) => useMutation(
    ({ id, data }) => apiService.put(`/workouts/${workoutId}/workout-sets/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workoutSets', workoutId]);
        queryClient.invalidateQueries(['workouts']);
        toast.success('Workout set updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteWorkoutSet = (workoutId) => useMutation(
    (id) => apiService.delete(`/workouts/${workoutId}/workout-sets/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workoutSets', workoutId]);
        queryClient.invalidateQueries(['workouts']);
        toast.success('Workout set deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // workout_reps
  const useWorkoutReps = (workoutId, workoutSetId) => useQuery(['workoutReps', workoutId, workoutSetId], () => apiService.get(`/workouts/${workoutId}/workout-sets/${workoutSetId}/workout-reps`).then(res => res.data), defaultQueryOptions);
  const useWorkoutRep = (workoutId, workoutSetId, id) => useQuery(['workoutRep', workoutId, workoutSetId, id], () => apiService.get(`/workouts/${workoutId}/workout-sets/${workoutSetId}/workout-reps/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateWorkoutRep = (workoutId, workoutSetId) => useMutation(
    (data) => apiService.post(`/workouts/${workoutId}/workout-sets/${workoutSetId}/workout-reps`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workoutReps', workoutId, workoutSetId]);
        toast.success('Workout rep created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateWorkoutRep = (workoutId, workoutSetId) => useMutation(
    ({ id, data }) => apiService.put(`/workouts/${workoutId}/workout-sets/${workoutSetId}/workout-reps/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workoutReps', workoutId, workoutSetId]);
        toast.success('Workout rep updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteWorkoutRep = (workoutId, workoutSetId) => useMutation(
    (id) => apiService.delete(`/workouts/${workoutId}/workout-sets/${workoutSetId}/workout-reps/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['workoutReps', workoutId, workoutSetId]);
        toast.success('Workout rep deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // athlete-workouts
  const useAthleteWorkouts = (queryParams) => useQuery(['athleteWorkouts', queryParams], () => apiService.get('/athlete-workouts', { params: queryParams }).then(res => res.data), defaultQueryOptions);
  const useAthleteWorkout = (id, queryParams) => useQuery(['athleteWorkout', id, queryParams], () => apiService.get(`/athlete-workouts/${id}`, { params: queryParams }).then(res => res.data), defaultQueryOptions);
  const useCreateAthleteWorkout = () => useMutation(
    (data) => apiService.post('/athlete-workouts', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkouts']);
        toast.success('Athlete workout created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateAthleteWorkout = () => useMutation(
    ({ id, data }) => apiService.put(`/athlete-workouts/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkouts']);
        toast.success('Athlete workout updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteAthleteWorkout = () => useMutation(
    (id) => apiService.delete(`/athlete-workouts/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkouts']);
        toast.success('Athlete workout deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // athlete-workout-sets
  const useAthleteWorkoutSets = (athleteWorkoutId) => useQuery(['athleteWorkoutSets', athleteWorkoutId], () => apiService.get(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets`).then(res => res.data), defaultQueryOptions);
  const useAthleteWorkoutSet = (athleteWorkoutId, id) => useQuery(['athleteWorkoutSet', athleteWorkoutId, id], () => apiService.get(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateAthleteWorkoutSet = (athleteWorkoutId) => useMutation(
    (data) => apiService.post(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkoutSets', athleteWorkoutId]);
        queryClient.invalidateQueries(['athleteWorkouts']);
        toast.success('Athlete workout set created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateAthleteWorkoutSet = (athleteWorkoutId) => useMutation(
    ({ id, data }) => apiService.put(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkoutSets', athleteWorkoutId]);
        queryClient.invalidateQueries(['athleteWorkouts']);
        toast.success('Athlete workout set updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteAthleteWorkoutSet = (athleteWorkoutId) => useMutation(
    (id) => apiService.delete(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkoutSets', athleteWorkoutId]);
        queryClient.invalidateQueries(['athleteWorkouts']);
        toast.success('Athlete workout set deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // athlete-workout-reps
  const useAthleteWorkoutReps = (athleteWorkoutId, athleteWorkoutSetId) => useQuery(['athleteWorkoutReps', athleteWorkoutId, athleteWorkoutSetId], () => apiService.get(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${athleteWorkoutSetId}/athlete-workout-reps`).then(res => res.data), defaultQueryOptions);
  const useAthleteWorkoutRep = (athleteWorkoutId, athleteWorkoutSetId, id) => useQuery(['athleteWorkoutRep', athleteWorkoutId, athleteWorkoutSetId, id], () => apiService.get(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${athleteWorkoutSetId}/athlete-workout-reps/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateAthleteWorkoutRep = (athleteWorkoutId, athleteWorkoutSetId) => useMutation(
    (data) => apiService.post(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${athleteWorkoutSetId}/athlete-workout-reps`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkoutReps', athleteWorkoutId, athleteWorkoutSetId]);
        toast.success('Athlete workout rep created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateAthleteWorkoutRep = (athleteWorkoutId, athleteWorkoutSetId) => useMutation(
    ({ id, data }) => apiService.put(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${athleteWorkoutSetId}/athlete-workout-reps/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkoutReps', athleteWorkoutId, athleteWorkoutSetId]);
        toast.success('Athlete workout rep updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteAthleteWorkoutRep = (athleteWorkoutId, athleteWorkoutSetId) => useMutation(
    (id) => apiService.delete(`/athlete-workouts/${athleteWorkoutId}/athlete-workout-sets/${athleteWorkoutSetId}/athlete-workout-reps/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['athleteWorkoutReps', athleteWorkoutId, athleteWorkoutSetId]);
        toast.success('Athlete workout rep deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // athletes_prs
  const useAthletesPrs = (athleteId) => useQuery(['athletesPrs', athleteId], () => apiService.get(`/athletes-prs?athlete_id=${athleteId}`).then(res => res.data), defaultQueryOptions);
  const useAthletePr = (athleteId, id) => useQuery(['athletePr', athleteId, id], () => apiService.get(`/athletes-prs/${id}?athlete_id=${athleteId}`).then(res => res.data), defaultQueryOptions);
  const useCreateAthletePr = () => useMutation(
    (data) => apiService.post('/athletes-prs', data),
    { 
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['athletesPrs', variables.athleteId]);
        toast.success('Athlete PR created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateAthletePr = () => useMutation(
    ({ id, data }) => apiService.put(`/athletes-prs/${id}`, data),
    { 
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['athletesPrs', variables.data.athleteId]);
        toast.success('Athlete PR updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteAthletePr = () => useMutation(
    ({ athleteId, id }) => apiService.delete(`/athletes-prs/${id}?athlete_id=${athleteId}`),
    { 
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['athletesPrs', variables.athleteId]);
        toast.success('Athlete PR deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // athletes_goals
  const useAthletesGoals = (athleteId) => useQuery(['athletesGoals', athleteId], () => apiService.get(`/athletes-goals?athlete_id=${athleteId}`).then(res => res.data), defaultQueryOptions);
  const useAthleteGoal = (athleteId, id) => useQuery(['athleteGoal', athleteId, id], () => apiService.get(`/athletes-goals/${id}?athlete_id=${athleteId}`).then(res => res.data), defaultQueryOptions);
  const useCreateAthleteGoal = () => useMutation(
    (data) => apiService.post('/athletes-goals', data),
    { 
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['athletesGoals', variables.athleteId]);
        toast.success('Athlete goal created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateAthleteGoal = () => useMutation(
    ({ id, data }) => apiService.put(`/athletes-goals/${id}`, data),
    { 
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['athletesGoals', variables.athleteId]);
        toast.success('Athlete goal updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteAthleteGoal = () => useMutation(
    ({ athleteId, id }) => apiService.delete(`/athletes-goals/${id}?athlete_id=${athleteId}`),
    { 
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['athletesGoals', variables.athleteId]);
        toast.success('Athlete goal deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );


  // team
  const useTeam = (id) => useQuery(['team', id], () => apiService.get(`/teams/${id}`).then(res => res.data), defaultQueryOptions);
  const useUpdateTeam = () => useMutation(
    ({ id, data }) => apiService.put(`/teams/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['team']);
        toast.success('Team updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // coaches
  const useCoaches = () => useQuery(['coaches'], () => apiService.get('/coaches').then(res => res.data), defaultQueryOptions);
  const useCoach = (id) => useQuery(['coach', id], () => apiService.get(`/coaches/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateCoach = () => useMutation(
    (data) => apiService.post('/coaches', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['coaches']);
        toast.success('Coach created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateCoach = () => useMutation(
    ({ id, data }) => apiService.put(`/coaches/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['coaches']);
        toast.success('Coach updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteCoach = () => useMutation(
    (id) => apiService.delete(`/coaches/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['coaches']);
        toast.success('Coach deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // check_goals
  const useCheckGoals = () => useQuery(['checkGoals'], () => apiService.get('/check-goals').then(res => res.data), defaultQueryOptions);
  const useCheckGoal = (id) => useQuery(['checkGoal', id], () => apiService.get(`/check-goals/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateCheckGoal = () => useMutation(
    (data) => apiService.post('/check-goals', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['checkGoals']);
        toast.success('Goal created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateCheckGoal = () => useMutation(
    ({ id, data }) => apiService.put(`/check-goals/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['checkGoals']);
        toast.success('Goal updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteCheckGoal = () => useMutation(
    (id) => apiService.delete(`/check-goals/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['checkGoals']);
        toast.success('Goal deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useClickCheckGoal = () => useMutation(
    ({ id, date }) => apiService.post(`/check-goals/${id}/click`, { date }),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['checkGoals']);
        toast.success('Goal updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // bulletins
  const useBulletins = () => useQuery(['bulletins'], () => apiService.get('/bulletins').then(res => res.data), defaultQueryOptions);
  const useBulletin = (id) => useQuery(['bulletin', id], () => apiService.get(`/bulletins/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateBulletin = () => useMutation(
    (data) => apiService.post('/bulletins', data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['bulletins']);
        toast.success('Bulletin created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateBulletin = () => useMutation(
    ({ id, data }) => apiService.put(`/bulletins/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['bulletins']);
        toast.success('Bulletin updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteBulletin = () => useMutation(
    (id) => apiService.delete(`/bulletins/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['bulletins']);
        toast.success('Bulletin deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useAddBulletinMember = () => useMutation(
    ({ id, memberId, memberType }) => apiService.post(`/bulletins/${id}/add_member`, { memberId, memberType }),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['bulletins']);
        toast.success('User added to bulletin successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useRemoveBulletinMember = () => useMutation(
    ({ id, memberId, memberType }) => apiService.delete(`/bulletins/${id}/remove_member`, { data: { memberId, memberType } }),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['bulletins']);
        toast.success('User removed from bulletin successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // posts
  const usePosts = (bulletinId) => useQuery(['posts', bulletinId], () => apiService.get(`/bulletins/${bulletinId}/posts`).then(res => res.data), defaultQueryOptions);
  const usePost = (bulletinId, id) => useQuery(['post', bulletinId, id], () => apiService.get(`/bulletins/${bulletinId}/posts/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreatePost = (bulletinId) => useMutation(
    (data) => apiService.post(`/bulletins/${bulletinId}/posts`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['posts', bulletinId]);
        toast.success('Post created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdatePost = (bulletinId) => useMutation(
    ({ id, data }) => apiService.put(`/bulletins/${bulletinId}/posts/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['posts', bulletinId]);
        toast.success('Post updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeletePost = (bulletinId) => useMutation(
    ({ id }) => apiService.delete(`/bulletins/${bulletinId}/posts/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['posts', bulletinId]);
        toast.success('Post deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // comments
  const useComments = (bulletinId, postId) => useQuery(['comments', bulletinId, postId], () => apiService.get(`/bulletins/${bulletinId}/posts/${postId}/comments`).then(res => res.data), defaultQueryOptions);
  const useComment = (bulletinId, postId, id) => useQuery(['comment', bulletinId, postId, id], () => apiService.get(`/bulletins/${bulletinId}/posts/${postId}/comments/${id}`).then(res => res.data), defaultQueryOptions);
  const useCreateComment = (bulletinId, postId) => useMutation(
    (data) => apiService.post(`/bulletins/${bulletinId}/posts/${postId}/comments`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['comments', bulletinId, postId]);
        toast.success('Comment created successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useUpdateComment = (bulletinId, postId) => useMutation(
    ({ id, data }) => apiService.put(`/bulletins/${bulletinId}/posts/${postId}/comments/${id}`, data),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['comments', bulletinId, postId]);
        toast.success('Comment updated successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useDeleteComment = (bulletinId, postId) => useMutation(
    ({ id }) => apiService.delete(`/bulletins/${bulletinId}/posts/${postId}/comments/${id}`),
    { 
      onSuccess: () => {
        queryClient.invalidateQueries(['comments', bulletinId, postId]);
        toast.success('Comment deleted successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );

  // calander
  const useCalanders = (queryParams) => useQuery(['calander', queryParams], () => apiService.get('/calanders', { params: queryParams }).then(res => res.data), defaultQueryOptions);
  // const useCalander = (id, queryParams) => useQuery(['calander', id, queryParams], () => apiService.get(`/athlete-workouts/${id}`, { params: queryParams }).then(res => res.data), defaultQueryOptions);


  // no token needed
  const useRegisterUser = () => useMutation(
    (data) => axios.post(`${API_BASE_URL}/registrations`, {user: data}),
    {
      onSuccess: () => {
        toast.success('User registered successfully');
      },
      onError: (error) => {
        handleApiError(error);
      }
    }
  );
  const useLoginUser = () => useMutation(
    (data) => axios.post(`${API_BASE_URL}/sessions`, data),
    {
      onSuccess: () => {
        toast.success('Login successful');
      },
      onError: (error) => {
        toast.error(`${error.response.data.error}`);
      }
    }
  );
  const usePasswordReset = () => useMutation(
    (data) => axios.post(`${API_BASE_URL}/password_reset`, data),
    {
      onSuccess: () => {
        toast.success('Password reset successful');
      },
      onError: (error) => {
        toast.error(`${error.response.data.error}`);
      }
    }
  );

  return {
    // helpers
    findScore,
    appCoachCount,
    appAthleteCount,
    appAthleteWorkoutsCount,
    appAthletePrCount,
    weather,
    // stripe
    createSubscription,
    // users
    useUsers,
    useUser,
    useCreateUser,
    useUpdateUser,
    useDeleteUser,
    // event_groups
    useEventGroups,
    useEventGroup,
    useCreateEventGroup,
    useUpdateEventGroup,
    useDeleteEventGroup,
    // athletes
    useAthletes,
    useAthlete,
    useCreateAthlete,
    useUpdateAthlete,
    useDeleteAthlete,
    useLinkEventGroups,
    useUnlinkEventGroups,
    // athletes_prs
    useAthletesPrs,
    useAthletePr,
    useCreateAthletePr,
    useUpdateAthletePr,
    useDeleteAthletePr,
    // athletes_goals
    useAthletesGoals,
    useAthleteGoal,
    useCreateAthleteGoal,
    useUpdateAthleteGoal,
    useDeleteAthleteGoal,
    // workouts
    useWorkouts,
    useWorkout,
    useCreateWorkout,
    useUpdateWorkout,
    useDeleteWorkout,
    // workout_sets
    useWorkoutSets,
    useWorkoutSet,
    useCreateWorkoutSet,
    useUpdateWorkoutSet,
    useDeleteWorkoutSet,
    // workout_reps
    useWorkoutReps,
    useWorkoutRep,
    useCreateWorkoutRep,
    useUpdateWorkoutRep,
    useDeleteWorkoutRep,
    //athlete-workouts
    useAthleteWorkouts,
    useAthleteWorkout,
    useCreateAthleteWorkout,
    useUpdateAthleteWorkout,
    useDeleteAthleteWorkout,
    // athlete-workout-sets
    useAthleteWorkoutSets,
    useAthleteWorkoutSet,
    useCreateAthleteWorkoutSet,
    useUpdateAthleteWorkoutSet,
    useDeleteAthleteWorkoutSet,
    // athlete-workout-reps
    useAthleteWorkoutReps,
    useAthleteWorkoutRep,
    useCreateAthleteWorkoutRep,
    useUpdateAthleteWorkoutRep,
    useDeleteAthleteWorkoutRep,
    // team
    useTeam,
    useUpdateTeam,
    // coaches
    useCoaches,
    useCoach,
    useCreateCoach,
    useUpdateCoach,
    useDeleteCoach,
    // check_goals
    useCheckGoals,
    useCheckGoal,
    useCreateCheckGoal,
    useUpdateCheckGoal,
    useDeleteCheckGoal,
    useClickCheckGoal,
    // bulletins
    useBulletins,
    useBulletin,
    useCreateBulletin,
    useUpdateBulletin,
    useDeleteBulletin,
    useAddBulletinMember,
    useRemoveBulletinMember,
    // posts
    usePosts,
    usePost,
    useCreatePost,
    useUpdatePost,
    useDeletePost,
    // comments
    useComments,
    useComment,
    useCreateComment,
    useUpdateComment,
    useDeleteComment,
    // calander
    useCalanders,
    // no token needed
    useRegisterUser,
    useLoginUser,
    usePasswordReset,
    // query client
    queryClient: queryClient,
  };
};

export default useApiService;