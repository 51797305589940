import React, { useContext, useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, BottomNavigation, BottomNavigationAction, Typography, Divider, Box, IconButton, Menu, MenuItem, Paper } from '@mui/material';
import RosterIcon from '@mui/icons-material/People';
import TrainingPlanIcon from '@mui/icons-material/FitnessCenter';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { AuthContext } from '../../context/authContext';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import BulletinIcon from '@mui/icons-material/Forum';
import { useNavigate, useLocation } from 'react-router-dom';
import FormTeam from '../teamUtils/formTeam';
import { FormUser, FormPassword } from '../user';
import Logo from './2.svg';
import MobileModal from '../viewModal/mobileModal';

// // Helper function to determine subscription status
// const getSubscriptionTier = (team) => {
//   if (!team?.subscription || team.subscription.status === 'past_due') {
//     return 'Free Plan';
//   }
//   return team.subscription.plan.includes('pro') ? 'Pro Plan' : 'Free Plan';
// };

const SideNav = () => {
  const { logout, userType, team } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTeamFormOpen, setIsTeamFormOpen] = useState(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [isPasswordFormOpen, setIsPasswordFormOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleTeamClick = () => {
    if (userType === 'coach') {
      setIsTeamFormOpen(true);
    }
  };

  const handleTeamFormClose = () => {
    setIsTeamFormOpen(false);
  };

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleUserFormOpen = () => {
    setIsUserFormOpen(true);
    handleSettingsClose();
  };

  const handleUserFormClose = () => {
    setIsUserFormOpen(false);
  };

  const handlePasswordFormOpen = () => {
    setIsPasswordFormOpen(true);
    handleSettingsClose();
  };

  const handlePasswordFormClose = () => {
    setIsPasswordFormOpen(false);
  };

  const navItems = [
    { icon: <HomeIcon />, label: 'Home', onClick: () => navigate('/home'), path: '/home' },
    { icon: <TrainingPlanIcon />, label: 'Training', onClick: () => navigate('/training-plan'), path: '/training-plan' },
    { icon: <BulletinIcon />, label: 'Bulletin', onClick: () => navigate('/bulletin'), path: '/bulletin' },
    { icon: <RosterIcon />, label: 'Roster', onClick: () => navigate('/roster'), path: '/roster' },
    { divider: true },
    { icon: <LogoutIcon />, label: 'Logout', onClick: handleLogout, path: '/logout' },
  ];

  const isSelected = (path) => {
    return location.pathname.startsWith(path) && path !== '/logout';
  };

  const SideNavContent = () => (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ margin: 2 }}>
        <Box textAlign="center">
          <img src={Logo} alt="Track Tools Pro" style={{ height: '40px' }} />
        </Box>
        <IconButton onClick={handleSettingsClick} size="small" sx={{ mt: '-10px' }}>
          <SettingsIcon fontSize="small" sx={{ color: 'white' }} />
        </IconButton>
      </Box>
      {team && team.name && !isMobile && (
        <Box 
          sx={{ 
            width: '100%', 
            backgroundColor: 'primary.main', 
            py: 1, 
            mb: 1, 
            cursor: userType === 'coach' ? 'pointer' : 'default'
          }}
          onClick={handleTeamClick}
        >
          <Typography variant="body2" align="center" sx={{ color: 'primary.contrastText' }}>
            {team.name}
          </Typography>
        </Box>
      )}
      <Divider />
      <List sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {navItems.map((item, index) => (
          item.divider ? (
            <Divider key={index} sx={{ mt: 2, mb: 2 }} />
          ) : (
            <ListItem 
              key={index} 
              onClick={item.onClick}
              sx={{ cursor: 'pointer' }}
            >
              <ListItemIcon sx={{ color: isSelected(item.path) ? 'primary.main' : 'white' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} sx={{ color: isSelected(item.path) ?  'primary.main' : 'white' }} />
            </ListItem>
          )
        ))}
      </List>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSettingsClose}
      >
        <MenuItem onClick={handleUserFormOpen}>Update User Info</MenuItem>
        <MenuItem onClick={handlePasswordFormOpen}>Update Password</MenuItem>
      </Menu>
    </>
  );

  const BottomNav = () => (
    <Paper 
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
    >
      <BottomNavigation
        showLabels
        sx={{
          width: '100%',
          backgroundColor: (t) => `${t.palette.background.dark}`,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          overflow: 'hidden',
        }}
        value={location.pathname}
      >
        {navItems.slice(0, 3).map((item, index) => (
          <BottomNavigationAction 
            key={index} 
            label={item.label} 
            icon={item.icon} 
            onClick={item.onClick} 
            value={item.path}
            sx={{ 
              color: isSelected(item.path) ? 'primary.main' : 'white',
              '&.Mui-selected': {
                color: 'primary.main',
              },
              transition: 'all 0.3s ease',
            }}
          />
        ))}
        <BottomNavigationAction 
          label="Menu"
          icon={<MenuIcon />}
          onClick={toggleModal}
          sx={{ 
            color: 'white',
            '&:hover': {
              color: 'primary.light',
            },
            transition: 'all 0.3s ease',
          }}
        />
      </BottomNavigation>
    </Paper>
  );

  return (
    <>
      {isMobile ? (
        <>
          <BottomNav />
          <MobileModal
            open={isModalOpen}
            onClose={toggleModal}
            sx={{
              backgroundColor: (t) => `${t.palette.background.dark}`,
              color: 'white',
            }}
          >
            <SideNavContent />
          </MobileModal>
        </>
      ) : (
        <Drawer
          variant="permanent"
          anchor="left"
          PaperProps={{
            sx: {
              border: 'none',
              borderRight: (theme) => `1px solid ${theme.palette.border.main}`,
              maxWidth: 250,
              minWidth: 240,
              backgroundColor: (t) => `${t.palette.background.dark}`,
            }
          }}
        >
          <SideNavContent />
        </Drawer>
      )}
      {userType === 'coach' && (
        <FormTeam
          open={isTeamFormOpen}
          handleClose={handleTeamFormClose}
          onTeamUpdated={() => {}} // You might want to handle team updates here
          team={team}
        />
      )}
      <FormUser
        open={isUserFormOpen}
        handleClose={handleUserFormClose}
      />
      <FormPassword
        open={isPasswordFormOpen}
        handleClose={handlePasswordFormClose}
      />
    </>
  );
};

export default SideNav;