import React, { useState, useContext } from "react";
import { Box, Typography, Button, Collapse } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import AthleteWorkoutSet from "./athleteWorkoutSet";
import useApiService from "../../../../../api/apiService";
import { SetForm } from "../../workoutsSection/forms/setForm";
import { AuthContext } from "../../../../../context/authContext";
import AreYouSure from "../../../../../components/utils/areYouSure/areYouSure";

const AthleteWorkoutPod = ({ workout }) => {
  const theme = useTheme();
  const { useDeleteAthleteWorkout, useCreateAthleteWorkoutSet } =
    useApiService();
  const deleteAthleteWorkout = useDeleteAthleteWorkout();
  const createAthleteWorkoutSet = useCreateAthleteWorkoutSet(workout?.id);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAddSetDialogOpen, setIsAddSetDialogOpen] = useState(false);
  const { userType } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);

  if (!workout) return null;

  const handleDeleteWorkout = () => {
    if (userType === "coach") {
      deleteAthleteWorkout.mutate(workout.id);
    }
  };

  const handleAddSet = async (data) => {
    if (userType === "coach") {
      try {
        await createAthleteWorkoutSet.mutateAsync(data);
        setIsAddSetDialogOpen(false);
      } catch (error) {
        console.error("Error adding set:", error);
      }
    }
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Box key={workout.id} sx={{ mb: 1, borderRadius: "8px" }}>
      <Box
        onClick={toggleExpand}
        sx={{
          cursor: "pointer",
          borderTop: `1px solid ${theme.palette.border.main}`,
          borderLeft: `1px solid ${theme.palette.border.main}`,
          borderRight: `1px solid ${theme.palette.border.main}`,
          borderBottom: expanded
            ? "none"
            : `1px solid ${theme.palette.border.main}`,
          borderRadius: expanded ? "0" : "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pl: "8px",
          backgroundColor: theme.palette.background.paperSecondary,
        }}
      >
        <Typography variant="body2">
          {workout.athlete?.name || "Unknown Athlete"} - {workout.workoutType}
        </Typography>
        <ExpandMoreIcon
          sx={{
            transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
            mr: 1,
          }}
        />
      </Box>
      <Collapse in={expanded}>
        <Box
          sx={{
            borderLeft: `1px solid ${theme.palette.border.main}`,
            borderRight: `1px solid ${theme.palette.border.main}`,
          }}
        >
          <AthleteWorkoutSet workout={workout} />
        </Box>
        {userType === "coach" && (
          <Box
            sx={{
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              borderTop: `1px solid ${theme.palette.border.main}`,
              border: `1px solid ${theme.palette.border.main}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              px: 1,
              backgroundColor: theme.palette.background.paperDark,
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: "0.6rem", mx: 1 }}
              startIcon={<AddIcon />}
              onClick={() => setIsAddSetDialogOpen(true)}
            >
              Add Set
            </Button>
            <Box pt={2} pb={2}></Box>
            <Button
              variant="outlined"
              size="small"
              sx={{ fontSize: "0.6rem", mx: 1 }}
              startIcon={<DeleteIcon />}
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              Delete Workout
            </Button>
          </Box>
        )}
      </Collapse>

      {userType === "coach" && (
        <>
          <AreYouSure
            isOpen={isDeleteDialogOpen}
            handleClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleDeleteWorkout}
            itemName="this workout"
            sx={{ zIndex: 13013 }}
          />

          <SetForm
            open={isAddSetDialogOpen}
            onClose={() => setIsAddSetDialogOpen(false)}
            onSave={handleAddSet}
          />
        </>
      )}
    </Box>
  );
};

export { AthleteWorkoutPod };
