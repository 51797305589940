import React, { useState, useContext } from "react";
import { Grid, Button } from "@mui/material";
import { AuthContext } from "../../context/authContext";
import useApiService from "../../api/apiService";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "../../components/form/form";
import { AnimatedGridBG } from "../../components/magicUI/animatedGridBG";
import PageWrapper from "../pageWrapper/pageWrapper";

const LogInSignUpPage = () => {
  const { login } = useContext(AuthContext);
  const apiService = useApiService();
  const navigate = useNavigate();
  const location = useLocation();
  const isCreateAccount = location.pathname === "/login/create-account";
  const [isLogin, setIsLogin] = useState(!isCreateAccount);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [teamName, setTeamName] = useState("");

  const { mutate: loginUser } = apiService.useLoginUser();
  const { mutate: registerUser } = apiService.useRegisterUser();

  const handleSwitchMode = () => {
    setIsLogin(!isLogin);
    navigate(isLogin ? "/login/create-account" : "/login");
  };

  const handleNavigateHome = () => {
    navigate("/");
  };

  const handleLogin = async (formData) => {
    loginUser(
      { email: formData.email, password: formData.password },
      {
        onSuccess: (response) => {
          const { user, token, type, team } = response.data;
          login(user, token, type, team);
        },
        onError: (error) => {
          console.error("Login failed", error);
        },
      }
    );
  };

  const handleSignUp = async (formData) => {
    if (formData.password !== formData.confirmPassword) {
      console.log("Passwords do not match");
      return;
    }
    registerUser(
      {
        email: formData.email,
        password: formData.password,
        first_name: formData.firstName,
        last_name: formData.lastName,
        team_name: formData.teamName,
      },
      {
        onSuccess: (response) => {
          const { user, token, type, team } = response.data;
          login(user, token, type, team);
        },
        onError: (error) => {
          console.error("Sign up failed", error);
        },
      }
    );
  };

  const fields = [
    {
      name: "email",
      label: "Email Address",
      type: "email",
      required: true,
      value: email,
      onChange: setEmail,
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      required: true,
      value: password,
      onChange: setPassword,
    },
    !isLogin && {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      required: true,
      value: confirmPassword,
      onChange: setConfirmPassword,
    },
    !isLogin && {
      name: "firstName",
      label: "First Name",
      type: "text",
      required: true,
      value: firstName,
      onChange: setFirstName,
    },
    !isLogin && {
      name: "lastName",
      label: "Last Name",
      type: "text",
      required: true,
      value: lastName,
      onChange: setLastName,
    },
    !isLogin && {
      name: "teamName",
      label: "Team Name",
      type: "text",
      required: true,
      value: teamName,
      onChange: setTeamName,
    },
    {
      name: "switchMode",
      label: isLogin ? "Switch to Sign Up" : "Switch to Login",
      type: "custom",
      render: () => (
        <>
          <Button onClick={handleSwitchMode} sx={{ mt: 2, mr: 2 }}>
            {isLogin ? "Create Account" : "Login"}
          </Button>
          <Button onClick={handleNavigateHome} sx={{ mt: 2 }}>
            Home
          </Button>
        </>
      ),
    },
  ].filter(Boolean);

  return (
    <PageWrapper ttp={true} tabs={[]} sx={{ m: 0, p: 0 }} policy={false}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh", position: "relative" }}
      >
        <AnimatedGridBG />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{ mx: "auto", mt: 10, position: "relative", zIndex: 1 }}
        >
          <Form
            open={true}
            onClose={() => {}}
            fields={fields}
            onSubmit={isLogin ? handleLogin : handleSignUp}
            title={isLogin ? "Login" : "Sign Up"}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default LogInSignUpPage;
