import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Button, IconButton, useTheme, Badge } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Logo from '../../components/sideNav/2.svg';
import { useIsland } from './islandContext';
import { motion } from 'framer-motion';

const Island = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const tabsRef = useRef(null);
  const [islandWidth, setIslandWidth] = useState(0);
  const [notificationCount] = useState(2);
  const showNotifications = false; // Set show notifications to false for now

  const {
    tabs,
    rootUrl,
    actions,
    ttp,
    activeTab,
    setActiveTab,
    showScrollButtons,
    setShowScrollButtons,
  } = useIsland();

  const checkScrollButtons = useCallback(() => {
    if (tabsRef.current) {
      const { scrollWidth, clientWidth } = tabsRef.current;
      setShowScrollButtons(scrollWidth > clientWidth);
    }
  }, [setShowScrollButtons]);

  const updateIslandWidth = useCallback(() => {
    if (tabsRef.current) {
      const newWidth = tabsRef.current.scrollWidth + 
        (ttp ? 180 : 0) +
        (showScrollButtons ? 80 : 0) + 
        (showNotifications ? 40 : 0) +
        (actions?.length > 0 ? actions.length * 40 : 0) +
        60; // Increased padding for wiggle room
      setIslandWidth(Math.max(newWidth, 150)); // Increased minimum width for more space
    }
  }, [ttp, showScrollButtons, actions, showNotifications]);

  useEffect(() => {
    if (tabs?.length > 0) {
      if (location.pathname === rootUrl) {
        navigate(`${rootUrl}/${tabs[0].path}`);
      }
      const index = tabs.findIndex(tab => location.pathname.includes(tab.path));
      setActiveTab(index >= 0 ? index : 0);
    }
    checkScrollButtons();
    updateIslandWidth();
  }, [tabs, rootUrl, location, navigate, setActiveTab, checkScrollButtons, updateIslandWidth]);

  const handleTabChange = (index) => {
    setActiveTab(index);
    navigate(`${rootUrl}/${tabs[index].path}`);
  };

  // const scrollTabs = (direction) => {
  //   if (tabsRef.current) {
  //     const scrollAmount = direction === 'left' ? -180 : 180; // Reduced scroll amount for smoother scrolling
  //     tabsRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  //   }
  // };

  return (
    <Box 
      sx={{ 
        mb: 2, 
        display: 'flex', 
        justifyContent: 'center',
        position: 'sticky',
        top: 16,
        zIndex: 10,
      }}
    >
      <motion.div
        style={{
          display: 'flex',
          borderRadius: '24px',
          overflow: 'hidden',
          backgroundColor: theme.palette.background.dark,
        }}
        initial={{ width: 0 }}
        animate={{ width: islandWidth }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', px: 1 }}> {/* Added horizontal padding */}
          {showNotifications && (
            <IconButton sx={{ color: 'white', mr: 1, position: 'relative' }}>
              <Badge 
                badgeContent={notificationCount} 
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    right: -3,
                    top: 3,
                    background: 'linear-gradient(45deg, #f44336 30%, #ff9800 90%)',
                    border: 0,
                    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                  },
                }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}
          {ttp && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mr: 2, minWidth: '80px' }}>
              <img src={Logo} alt="Track Tools Pro" style={{ height: '30px', maxWidth: '100%' }} />
            </Box>
          )}
          {/* {showScrollButtons && tabs?.length > 3 && (
            <IconButton onClick={() => scrollTabs('left')} sx={{ color: 'white', mr: 1 }}>
              <ChevronLeftIcon />
            </IconButton>
          )} */}
          <Box
            ref={tabsRef}
            sx={{
              display: 'flex',
              overflowX: 'auto',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              msOverflowStyle: 'none',
              justifyContent: 'flex-start', // Changed to flex-start for better control
              height: '44px', // Increased height for more vertical space
              px: 1, // Added horizontal padding inside the scrollable area
            }}
          >
            {tabs?.map((tab, index) => (
              <Button
                key={index}
                onClick={() => handleTabChange(index)}
                sx={{
                  py: 0.5,
                  px: 1.5,
                  color: activeTab === index ? theme.palette.primary.contrastText : 'white',
                  backgroundColor: activeTab === index ? theme.palette.primary.main : 'transparent',
                  '&:hover': {
                    backgroundColor: activeTab === index ? theme.palette.primary.dark : theme.palette.action.hover,
                  },
                  transition: 'all 0.3s ease',
                  whiteSpace: 'nowrap',
                  borderRadius: '16px',
                  margin: '4px 2px', // Added horizontal margin for spacing between buttons
                  minHeight: '36px', // Increased minimum height
                  fontSize: '0.875rem',
                }}
                disabled={tab.disabled}
              >
                {tab.label}
              </Button>
            ))}
          </Box>
          {/* {showScrollButtons && tabs?.length > 3 && (
            <IconButton onClick={() => scrollTabs('right')} sx={{ color: 'white', ml: 1 }}>
              <ChevronRightIcon />
            </IconButton>
          )} */}
          {actions?.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto', mr: 1 }}>
              {actions.map((action, index) => (
                <IconButton
                  key={index}
                  onClick={action.action}
                  sx={{
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                    transition: 'all 0.3s ease',
                    ml: 0.5, // Added left margin for spacing
                  }}
                >
                  {action.label === '+' ? <AddIcon /> : action.label}
                </IconButton>
              ))}
            </Box>
          )}
        </Box>
      </motion.div>
    </Box>
  );
};

export default Island;
