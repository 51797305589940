import React, { useState } from "react";
import { Box, Typography, Grid, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import useApiService from "../../../../api/apiService";
import ComponentWrapper from "../../../../components/utils/wrappers/componentWrapper";
import { useTheme } from "@mui/material/styles";
import { RepForm } from "./forms";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";

const repPaceOptions = [
  { value: "recovery", text: "Recovery" },
  { value: "easy", text: "Easy" },
  { value: "moderate", text: "Moderate" },
  { value: "tempo", text: "Tempo" },
  { value: "threshold", text: "Threshold" },
  { value: "cv", text: "CV" },
  { value: "aerobic_power", text: "Aerobic Power" },
  { value: "vo2_max", text: "VO2 Max" },
  { value: "anaerobic_endurance_mile", text: "Anaerobic Endurance Mile" },
  { value: "anaerobic_endurance_1500", text: "Anaerobic Endurance 1500" },
  { value: "anaerobic_power", text: "Anaerobic Power" },
  { value: "speed_endurance", text: "Speed Endurance" },
  { value: "pace_60", text: "60m PR" },
  { value: "pace_100", text: "100m PR" },
  { value: "pace_200", text: "200m PR" },
  { value: "pace_300", text: "300m PR" },
  { value: "pace_400", text: "400m PR" },
  { value: "pace_60H", text: "60H PR" },
  { value: "pace_100H", text: "100H PR" },
  { value: "pace_110H", text: "110H PR" },
  { value: "pace_300H", text: "300H PR" },
  { value: "pace_400H", text: "400H PR" },
  { value: "pace_600", text: "600m PR" },
  { value: "pace_800", text: "800m PR" },
  { value: "pace_1000", text: "1000m PR" },
  { value: "pace_1500", text: "1500m PR" },
  { value: "pace_MILE", text: "Mile PR" },
  { value: "pace_1600", text: "1600m PR" },
  { value: "pace_3000", text: "3000m PR" },
  { value: "pace_3000S", text: "3k Steeplechase PR" },
  { value: "pace_3200", text: "3200m PR" },
  { value: "pace_5000", text: "5000m PR" },
  { value: "pace_10000", text: "10,000m PR" },
  { value: "pace_4K_XC", text: "4K Cross Country PR" },
  { value: "pace_5K_XC", text: "5K Cross Country PR" },
  { value: "pace_6K_XC", text: "6K Cross Country PR" },
  { value: "pace_7K_XC", text: "7K Cross Country PR" },
  { value: "pace_8K_XC", text: "8K Cross Country PR" },
  { value: "pace_10K_XC", text: "10K Cross Country PR" },
  { value: "pace_4_MILE_XC", text: "4 Mile Cross Country PR" },
  { value: "goal_60", text: "60m Goal" },
  { value: "goal_100", text: "100m Goal" },
  { value: "goal_200", text: "200m Goal" },
  { value: "goal_300", text: "300m Goal" },
  { value: "goal_400", text: "400m Goal" },
  { value: "goal_60H", text: "60H Goal" },
  { value: "goal_100H", text: "100H Goal" },
  { value: "goal_110H", text: "110H Goal" },
  { value: "goal_300H", text: "300H Goal" },
  { value: "goal_400H", text: "400H Goal" },
  { value: "goal_600", text: "600m Goal" },
  { value: "goal_800", text: "800m Goal" },
  { value: "goal_1000", text: "1000m Goal" },
  { value: "goal_1500", text: "1500m Goal" },
  { value: "goal_MILE", text: "Mile Goal" },
  { value: "goal_1600", text: "1600m Goal" },
  { value: "goal_3000", text: "3000m Goal" },
  { value: "goal_3000S", text: "3k Steeplechase Goal" },
  { value: "goal_3200", text: "3200m Goal" },
  { value: "goal_5000", text: "5000m Goal" },
  { value: "goal_10000", text: "10,000m Goal" },
  { value: "goal_4K_XC", text: "4K Cross Country Goal" },
  { value: "goal_5K_XC", text: "5K Cross Country Goal" },
  { value: "goal_6K_XC", text: "6K Cross Country Goal" },
  { value: "goal_7K_XC", text: "7K Cross Country Goal" },
  { value: "goal_8K_XC", text: "8K Cross Country Goal" },
  { value: "goal_10K_XC", text: "10K Cross Country Goal" },
  { value: "goal_4_MILE_XC", text: "4 Mile Cross Country Goal" },
];

const WorkoutRepPod = ({ workoutId, workoutSetId }) => {
  const {
    useWorkoutReps,
    useCreateWorkoutRep,
    useUpdateWorkoutRep,
    useDeleteWorkoutRep,
  } = useApiService();
  const { data: reps } = useWorkoutReps(workoutId, workoutSetId);
  const createWorkoutRepMutation = useCreateWorkoutRep(workoutId, workoutSetId);
  const updateWorkoutRepMutation = useUpdateWorkoutRep(workoutId, workoutSetId);
  const deleteWorkoutRepMutation = useDeleteWorkoutRep(workoutId, workoutSetId);
  const theme = useTheme();

  const [openRepDialog, setOpenRepDialog] = useState(false);
  const [selectedRep, setSelectedRep] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const handleClickOpenRep = (rep) => {
    if (rep === null) {
      setSelectedRep(null);
      setFormKey((prevKey) => prevKey + 1);
    } else {
      setSelectedRep(rep);
    }
    setOpenRepDialog(true);
  };

  const handleCloseRepDialog = () => {
    setOpenRepDialog(false);
    setSelectedRep(null);
  };

  const handleSaveRep = async (repData) => {
    try {
      if (selectedRep) {
        await updateWorkoutRepMutation.mutateAsync({
          id: selectedRep.id,
          data: repData,
        });
      } else {
        await createWorkoutRepMutation.mutateAsync(repData);
      }
      handleCloseRepDialog();
    } catch (error) {
      console.error("Error saving rep:", error);
    }
  };

  const handleDeleteRep = async () => {
    try {
      await deleteWorkoutRepMutation.mutateAsync(selectedRep.id);
      handleCloseRepDialog();
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting rep:", error);
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const convertSecondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    const formattedSeconds = remainingSeconds.endsWith(".00")
      ? Math.floor(remainingSeconds)
      : remainingSeconds;
    return minutes > 0
      ? `${minutes}:${formattedSeconds < 10 ? "0" : ""}${formattedSeconds}`
      : `${formattedSeconds}`;
  };

  const formatRepDetails = (rep) => {
    if (rep.repType === "text") {
      return rep.text;
    } else if (rep.distanceUnit === "time") {
      return `${rep.reps}x ${convertSecondsToTime(rep.timeInSeconds)}`;
    } else {
      return `${rep.reps}x ${rep.distance} ${rep.distanceUnit}`;
    }
  };

  return (
    <Box
      sx={{
        borderTop: `1px solid ${theme.palette.border.main}`,
        width: "100%",
      }}
    >
      <Box sx={{ ml: 2, mr: 2, pb: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, mt: 2 }}>
          <Tooltip title="Add Rep">
            <IconButton 
              onClick={() => handleClickOpenRep(null)}
              sx={{
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Grid container spacing={2}>
          {reps &&
            [...reps, { isPlaceholder: true }]
              .sort((a, b) => a.position - b.position)
              .map((rep, repIndex) => (
                <Grid item xs={12} sm={6} md={4} key={repIndex}>
                  {rep.isPlaceholder ? (
                    <ComponentWrapper
                      sx={{
                        borderBottom: `1px solid ${theme.palette.border.main}`,
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        cursor: "pointer",
                        transition: 'background-color 0.3s, transform 0.2s',
                        '&:hover': {
                          backgroundColor: theme.palette.action.hover,
                          transform: 'translateY(-2px)',
                        },
                      }}
                      onClick={() => handleClickOpenRep(null)}
                      tooltip="Add New Rep"
                    >
                      <Box sx={{ textAlign: "center" }}>
                        <AddIcon sx={{ fontSize: 40, mb: 1 }} />
                        <Typography variant="body2">Add New Rep</Typography>
                      </Box>
                    </ComponentWrapper>
                  ) : (
                    <ComponentWrapper
                      sx={{
                        borderBottom: `1px solid ${theme.palette.border.main}`,
                        padding: 0,
                        position: "relative",
                        transition: 'box-shadow 0.3s, transform 0.2s',
                        '&:hover': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                          transform: 'translateY(-2px)',
                        },
                      }}
                      onClick={() => handleClickOpenRep(rep)}
                      tooltip={`Edit Rep ${repIndex + 1}`}
                    >
                      <Box
                        sx={{
                          borderBottom: `1px solid ${theme.palette.border.main}`,
                          textAlign: "center",
                          borderRadius: '24px 24px 0 0',
                          backgroundColor: (theme) => theme.palette.background.paperDark,
                        }}
                      >
                        <Typography variant="body2">
                          {rep.reps > 1
                            ? `Reps ${repIndex + 1}`
                            : `Rep ${repIndex + 1}`}
                        </Typography>
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "4px",
                            transition: 'color 0.2s',
                            '&:hover': {
                              color: theme.palette.error.main,
                            },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedRep(rep);
                            handleOpenDeleteDialog();
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          ml: 2, 
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {formatRepDetails(rep)}
                      </Typography>
                      {rep.repType !== "text" && (
                        <>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              ml: 2,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {repPaceOptions.find(
                              (option) => option.value === rep.pace
                            )?.text || rep.pace}
                            {rep.pacePercentage !== 100 &&
                              ` (${rep.pacePercentage}%)`}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              ml: 2,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            Recovery: {convertSecondsToTime(rep.recovery)}
                          </Typography>
                        </>
                      )}
                    </ComponentWrapper>
                  )}
                </Grid>
              ))}
        </Grid>
        <RepForm
          key={formKey}
          open={openRepDialog}
          onClose={handleCloseRepDialog}
          onSave={handleSaveRep}
          onDelete={handleOpenDeleteDialog}
          initialData={selectedRep}
        />
        <AreYouSure
          isOpen={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          onConfirm={handleDeleteRep}
          itemName="this rep"
        />
      </Box>
    </Box>
  );
};

export default WorkoutRepPod;
