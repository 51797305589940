import React, { useContext, useState } from 'react';
import { Box, Typography, Button, Grid, Paper, useTheme } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import { AuthContext } from '../../../context/authContext';
import SubscriptionForm from '../../../components/stripe/subscriptionForm';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const TierFeature = ({ icon, text }) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <motion.div whileHover={{ scale: 1.2 }} transition={{ duration: 0.3 }}>
        {icon}
      </motion.div>
      <Typography variant="body2" sx={{ ml: 1 }}>{text}</Typography>
    </Box>
  </motion.div>
);

const SubTiers = () => {
  const { team, token, userType } = useContext(AuthContext);
  const [openSubscriptionForm, setOpenSubscriptionForm] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenSubscriptionForm = () => {
    if (!token) {
      navigate('/login/create-account');
    } else {
      setOpenSubscriptionForm(true);
    }
  };

  const handleCloseSubscriptionForm = () => {
    setOpenSubscriptionForm(false);
  };

  const tiers = [
    {
      name: "Free Plan",
      icon: <CheckCircleOutlineIcon sx={{ fontSize: 40, color: theme.palette.success.main }} />,
      features: [
        { icon: <FitnessCenterIcon fontSize="small" />, text: "Workout Generation" },
        { icon: <PeopleIcon fontSize="small" />, text: "10 Athlete Profiles" },
        { icon: <PersonIcon fontSize="small" />, text: "1 Coach Profile" },
        { icon: <EventIcon fontSize="small" />, text: "Training Plan" },
        { icon: <EventIcon fontSize="small" />, text: "Event Groups" },
      ],
    },
    {
      name: "Pro Plan",
      icon: <StarIcon sx={{ fontSize: 40, color: 'gold' }} />,
      features: [
        { icon: <FitnessCenterIcon fontSize="small" />, text: "All Free Plan Features" },
        { icon: <PeopleIcon fontSize="small" />, text: "Unlimited Athlete Profiles" },
        { icon: <PersonIcon fontSize="small" />, text: "Unlimited Coach Profiles" },
        { icon: <EventIcon fontSize="small" />, text: "Training Log (coming soon)" },
      ],
    },
  ];

  const getCurrentPlan = () => {
    if (team?.subscription?.plan === "plan_pro_yearly" || team?.subscription?.plan === "plan_pro_monthly") {
      if (team?.subscription?.status === "past_due") {
        return "Free Plan";
      }
      return "Pro Plan";
    } else {
      return "Free Plan";
    }
  };

  const currentPlan = getCurrentPlan();

  if (userType && userType !== 'coach') {
    return null;
  }

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      sx={{
        backgroundColor: theme.palette.background.paperSecondary,
        borderRadius: 3,
        boxShadow: theme.shadows[6],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, type: 'spring', stiffness: 100 }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Choose Your Plan
        </Typography>
      </motion.div>
      <Grid container spacing={3} justifyContent="center" sx={{ mt: 2 }}>
        <AnimatePresence>
          {tiers.map((tier, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ scale: 1.05 }}
                style={{ height: '100%' }}
              >
                <Paper 
                  elevation={10} 
                  sx={{ 
                    p: 3, 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    borderRadius: '20px',
                    background: tier.name === "Pro Plan"
                      ? `linear-gradient(135deg, #FFD700, #B8860B)`
                      : currentPlan === tier.name 
                        ? `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`
                        : `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.grey[100]})`,
                    color: tier.name === "Pro Plan" || currentPlan === tier.name ? 'white' : 'inherit',
                    transition: 'all 0.3s ease-in-out',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: '-50%',
                      left: '-50%',
                      width: '200%',
                      height: '200%',
                      background: 'radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%)',
                    },
                    boxShadow: tier.name === "Pro Plan" ? '0 10px 20px rgba(0,0,0,0.2)' : 'inherit',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, height: '60px' }}>
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.5, type: 'spring', stiffness: 200 }}
                    >
                      {tier.icon}
                    </motion.div>
                  </Box>
                  <Typography variant="h5" align="center" gutterBottom fontWeight="bold">
                    {tier.name}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }}>
                    {tier.features.map((feature, idx) => (
                      <TierFeature key={idx} icon={feature.icon} text={feature.text} />
                    ))}
                  </Box>
                  {currentPlan === tier.name && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.5, delay: 0.5 }}
                    >
                      <Typography variant="subtitle1" align="center" sx={{ mt: 2, fontWeight: 'bold' }}>
                        Current Plan
                      </Typography>
                    </motion.div>
                  )}
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </AnimatePresence>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, pb: 4 }}>
        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleOpenSubscriptionForm}
            size="large"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              padding: '10px 30px',
              borderRadius: '25px',
            }}
          >
            {!token ? 'Create Account to Subscribe' : currentPlan === "Pro Plan" ? 'View Subscription Details' : 'Upgrade to Pro'}
          </Button>
        </motion.div>
      </Box>
      {token && (
        <AnimatePresence>
          {openSubscriptionForm && (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
            >
              <SubscriptionForm open={openSubscriptionForm} onClose={handleCloseSubscriptionForm} />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Box>
  );
};

export default SubTiers;
