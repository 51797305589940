import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Badge,
  Paper,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import { Day } from "./_components";
import useApiService from "../../api/apiService";
import { SelectAthlete } from "../../pages/roster/_components/eventGroupSection/_components/selectAthlete";
import { SelectEventGroup } from "./_components/selectEventGroup";

const Calander = ({ ModalComponent, DisplayModels }) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs().startOf("month"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [isBulkSelectMode, setIsBulkSelectMode] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSelectAthleteOpen, setIsSelectAthleteOpen] = useState(false);
  const [isSelectEventGroupOpen, setIsSelectEventGroupOpen] = useState(false);
  const [selectedAthleteIds, setSelectedAthleteIds] = useState([]);
  const [selectedEventGroupIds, setSelectedEventGroupIds] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { useCalanders, useAthletes, useEventGroups } = useApiService();
  const start_date = currentMonth.startOf("month").format("YYYY-MM-DD");
  const end_date = currentMonth.endOf("month").format("YYYY-MM-DD");
  const queryParams = {
    startDate: start_date,
    endDate: end_date,
    modelNames: DisplayModels,
    ids: selectedAthleteIds,
  };
  const { data: calanderData } = useCalanders(queryParams);
  const { data: athletes } = useAthletes();
  const { data: eventGroups } = useEventGroups();

  const handlePrevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth(currentMonth.add(1, "month"));
  };

  const handleDayClick = (day) => {
    if (isBulkSelectMode) {
      const dateString = day.format("YYYY-MM-DD");
      setSelectedDates((prev) =>
        prev.includes(dateString)
          ? prev.filter((d) => d !== dateString)
          : [...prev, dateString]
      );
    } else {
      setSelectedDate(day);
      setIsModalOpen(true);
    }
  };

  const handleBulkSelectComplete = () => {
    if (selectedDates.length > 0) {
      setIsModalOpen(true);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBulkSelectToggle = () => {
    setIsBulkSelectMode(!isBulkSelectMode);
    if (isBulkSelectMode) {
      setSelectedDates([]);
    }
    handleMenuClose();
  };

  const handleCancelBulkAdd = () => {
    setIsBulkSelectMode(false);
    setSelectedDates([]);
  };

  const handleViewAsClick = () => {
    setIsSelectAthleteOpen(true);
    handleMenuClose();
  };

  const handleViewAsEventGroupClick = () => {
    setIsSelectEventGroupOpen(true);
    handleMenuClose();
  };

  const handleToggleAthlete = (athleteId) => {
    setSelectedAthleteIds((prev) =>
      prev.includes(athleteId)
        ? prev.filter((id) => id !== athleteId)
        : [...prev, athleteId]
    );
  };

  const handleSaveSelectedAthletes = () => {
    setIsSelectAthleteOpen(false);
  };

  const handleSaveSelectedEventGroups = (eventGroupIds) => {
    setSelectedEventGroupIds(eventGroupIds);
    const selectedGroups = eventGroups.filter((group) =>
      eventGroupIds.includes(group.id)
    );
    const athleteIds = new Set();
    selectedGroups.forEach((group) => {
      group.athletes.forEach((athlete) => athleteIds.add(athlete.id));
    });
    setSelectedAthleteIds(Array.from(athleteIds));
    setIsSelectEventGroupOpen(false);
  };

  const generateDaysInMonth = () => {
    const startOfMonth = currentMonth.startOf("month");
    const endOfMonth = currentMonth.endOf("month");
    const days = [];
    let day = startOfMonth.startOf("week");

    while (day <= endOfMonth.endOf("week")) {
      days.push(day);
      day = day.add(1, "day");
    }

    return days;
  };

  const daysInMonth = generateDaysInMonth();

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 2,
        overflow: "hidden",
        bgcolor: theme.palette.background.paper,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={theme.palette.primary.main}
        color={theme.palette.primary.contrastText}
        p={2}
        sx={{
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box display="flex" alignItems="center">
          <CalendarTodayIcon sx={{ mr: 2 }} />
          <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold" sx={{ letterSpacing: 1 }}>
            {currentMonth.format("MMMM YYYY")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title="Previous Month">
            <IconButton
              onClick={handlePrevMonth}
              size={isMobile ? "small" : "medium"}
              color="inherit"
              sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}
            >
              <ArrowBackIosNewIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next Month">
            <IconButton
              onClick={handleNextMonth}
              size={isMobile ? "small" : "medium"}
              color="inherit"
              sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}
            >
              <ArrowForwardIosIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Options">
            <IconButton
              onClick={handleMenuOpen}
              size={isMobile ? "small" : "medium"}
              color="inherit"
              sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } }}
            >
              <FilterListIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleBulkSelectToggle}>
          {isBulkSelectMode ? "Disable Bulk Select" : "Enable Bulk Select"}
        </MenuItem>
        <MenuItem onClick={handleViewAsClick}>View as athlete</MenuItem>
        <MenuItem onClick={handleViewAsEventGroupClick}>
          View as Event Group
        </MenuItem>
      </Menu>
      {isBulkSelectMode && (
        <Box display="flex" justifyContent="center" bgcolor={theme.palette.background.default} p={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBulkSelectComplete}
            disabled={selectedDates.length === 0}
            sx={{ 
              mr: 1, 
              fontSize: isMobile ? "0.7rem" : "0.875rem",
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              '&:hover': {
                boxShadow: '0 4px 6px rgba(0,0,0,0.15)',
              }
            }}
          >
            Add Workout
            <Badge
              badgeContent={selectedDates.length}
              color="secondary"
              sx={{ ml: 1 }}
            />
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelBulkAdd}
            sx={{ 
              fontSize: isMobile ? "0.7rem" : "0.875rem",
              borderColor: theme.palette.secondary.main,
              color: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme.palette.secondary.light,
              }
            }}
          >
            Cancel Bulk Add
          </Button>
        </Box>
      )}
      <Box>
        <Grid container spacing={0}>
          {["S", "M", "T", "W", "T", "F", "S"].map((dayName) => (
            <Grid item xs={12 / 7} key={dayName}>
              <Typography
                variant={isMobile ? "caption" : "subtitle2"}
                align="center"
                fontWeight="bold"
                sx={{ borderBottom: 1, borderColor: theme.palette.divider, py: 1 }}
              >
                {dayName}
              </Typography>
            </Grid>
          ))}
          {daysInMonth.map((day, index) => {
            const dateString = day.format("YYYY-MM-DD");
            return (
              <Grid item xs={12 / 7} key={dateString}>
                <Box
                  sx={{
                    border: 1,
                    borderColor: theme.palette.divider,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Day
                    day={day}
                    currentMonth={currentMonth}
                    onClick={() => handleDayClick(day)}
                    data={calanderData ? calanderData[dateString] : null}
                    isBulkSelected={selectedDates.includes(dateString)}
                    isMobile={isMobile}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {selectedDate && !isBulkSelectMode && isModalOpen && (
        <ModalComponent
          date={selectedDate}
          onClose={() => {
            setSelectedDate(null);
            setIsModalOpen(false);
          }}
        />
      )}
      {isBulkSelectMode && isModalOpen && (
        <ModalComponent
          dates={selectedDates}
          onClose={() => {
            setSelectedDates([]);
            setIsBulkSelectMode(false);
            setIsModalOpen(false);
          }}
        />
      )}
      {isSelectAthleteOpen && (
        <SelectAthlete
          open={isSelectAthleteOpen}
          onClose={() => setIsSelectAthleteOpen(false)}
          allAthletes={athletes || []}
          selectedAthletes={selectedAthleteIds}
          onToggleAthlete={handleToggleAthlete}
          onSave={handleSaveSelectedAthletes}
        />
      )}
      {isSelectEventGroupOpen && (
        <SelectEventGroup
          open={isSelectEventGroupOpen}
          onClose={() => setIsSelectEventGroupOpen(false)}
          eventGroups={eventGroups || []}
          selectedEventGroupIds={selectedEventGroupIds}
          onSave={handleSaveSelectedEventGroups}
        />
      )}
    </Paper>
  );
};

export default Calander;
