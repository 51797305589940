import React, { useMemo } from 'react';
import { Box, Typography, Chip, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';

const generateColor = (seed) => {
  const hash = seed.split('').reduce((acc, char) => {
    const charCode = char.charCodeAt(0);
    return ((acc << 5) - acc) + charCode | 0;
  }, 0);
  return `#${(hash & 0x00FFFFFF).toString(16).padStart(6, '0')}`;
};

const Day = ({ day, currentMonth, optionalDay, onClick, data, sx, isBulkSelected }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isToday = day.isSame(dayjs(), 'day');
  const isPast = day.isBefore(dayjs(), 'day');

  const workoutColors = useMemo(() => {
    if (!data || !data.athleteWorkout) return {};
    return data.athleteWorkout.reduce((acc, workout) => {
      if (workout.workoutData && workout.workoutData.name) {
        acc[workout.workoutData.name] = generateColor(workout.workoutData.name);
      }
      return acc;
    }, {});
  }, [data]);

  const renderModelCounts = () => {
    if (!data) return null;

    return Object.keys(data).map((modelName) => {
      if (data[modelName].length === 0) return null;

      if (modelName === 'athleteWorkout') {
        const workoutGroups = data[modelName].reduce((acc, workout) => {
          const name = workout.workoutData && workout.workoutData.name;
          if (!acc[name]) acc[name] = [];
          acc[name].push(workout);
          return acc;
        }, {});

        return (
          <>
            {Object.entries(workoutGroups).map(([workoutName, workouts]) => (
              <Box
                key={workoutName}
                sx={{
                  backgroundColor: `${workoutColors[workoutName]}33`,
                  fontSize: isMobile ? '0.4rem' : '0.75rem',
                  padding: '0px',
                  borderRadius: '2px',
                  marginBottom: '2px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  opacity: isPast ? 0.5 : 1,
                }}
              >
                <Typography variant="caption" sx={{ display: 'block', overflow: 'hidden' }}>
                  {workoutName}
                </Typography>
              </Box>
            ))}
          </>
        );
      }

      return (
        <Typography 
          key={modelName} 
          variant="caption" 
          sx={(theme) => ({ 
            display: 'block', 
            padding: '2px', 
            borderRadius: '2px', 
            fontSize: isMobile ? '0.5rem' : '0.75rem',
            opacity: isPast ? 0.5 : 1,
          })}
        >
          {modelName}: {data[modelName].length}
        </Typography>
      );
    });
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        minHeight: isMobile ? '100px' : '140px',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        backgroundColor: isBulkSelected 
          ? 'rgba(0, 0, 0, 0.2)' 
          : isToday 
            ? theme.palette.background.paperSecondary
            : 'inherit',
        ...sx
      }}
    >
      {day.month() === currentMonth.month() && (
        isMobile ? (
          <Typography
            variant="h6"
            sx={{
              position: 'absolute',
              top: 2,
              left: 2,
              fontSize: '1rem',
              color: isToday ? theme.palette.primary.main : isPast ? theme.palette.text.secondary : theme.palette.text.primary,
            }}
          >
            {day.format('D')}
          </Typography>
        ) : (
          <Chip 
            label={day.format('D')}
            color={isToday ? 'primary' : 'default'}
            size="small"
            sx={{ 
              position: 'absolute', 
              top: 8, 
              left: 8,
              color: isToday ? theme.palette.primary.contrastText : isPast ? theme.palette.text.secondary : theme.palette.text.primary,
              backgroundColor: isToday ? theme.palette.primary.main : 'inherit',
            }}
          />
        )
      )}
      <Box sx={{ mt: isMobile ? 6 : 8, p: isMobile ? 0.5 : 1 }}>
        {renderModelCounts()}
      </Box>
    </Box>
  );
};

export { Day };