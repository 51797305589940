import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button, List, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, Chip, Badge, CircularProgress } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import PageWrapper from '../pageWrapper/pageWrapper';
import useApiService from '../../api/apiService';
import Form from '../../components/form/form';
import Post from './components/post';
import { AuthContext } from '../../context/authContext';
import EditIcon from '@mui/icons-material/Edit';
import GroupIcon from '@mui/icons-material/Group';
import ComponentWrapper from '../../components/utils/wrappers/componentWrapper';
import AreYouSure from '../../components/utils/areYouSure/areYouSure';

const Bulletin = () => {
  const [bulletins, setBulletins] = useState([]);
  const [selectedBulletin, setSelectedBulletin] = useState(null);
  const [openBulletinForm, setOpenBulletinForm] = useState(false);
  const [openPostForm, setOpenPostForm] = useState(false);
  const [editingBulletin, setEditingBulletin] = useState(false);
  const [openMemberDialog, setOpenMemberDialog] = useState(false);
  const [coaches, setCoaches] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

  const { bulletinId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { userType } = useContext(AuthContext);

  const {
    useBulletins,
    useCreateBulletin,
    usePosts,
    useCreatePost,
    useUpdateBulletin,
    useCoaches,
    useAthletes,
    useAddBulletinMember,
    useRemoveBulletinMember,
  } = useApiService();

  const { data: bulletinsData, isLoading: isBulletinsLoading, error: bulletinsError } = useBulletins();
  const createBulletinMutation = useCreateBulletin();
  const updateBulletinMutation = useUpdateBulletin();
  const { data: coachesData } = useCoaches();
  const { data: athletesData } = useAthletes();
  const addBulletinMemberMutation = useAddBulletinMember();
  const removeBulletinMemberMutation = useRemoveBulletinMember();
  
  const currentBulletinId = bulletinId || (isNaN(location.pathname.split('/').pop()) ? null : location.pathname.split('/').pop());
  console.log('Current Bulletin ID:', currentBulletinId);
  
  const { data: postsData, isLoading: isPostsLoading, error: postsError } = usePosts(currentBulletinId);
  const createPostMutation = useCreatePost(currentBulletinId);

  useEffect(() => {
    if (bulletinsData) {
      setBulletins(bulletinsData);
      if (currentBulletinId) {
        const selected = bulletinsData.find(b => b.id.toString() === currentBulletinId);
        if (selected) {
          setSelectedBulletin(selected);
        } else {
          navigate('/bulletin');
        }
      }
    }
    if (coachesData) setCoaches(coachesData);
    if (athletesData) setAthletes(athletesData);
  }, [bulletinsData, currentBulletinId, navigate, coachesData, athletesData]);

  const handleCreateBulletin = async (data) => {
    try {
      const response = await createBulletinMutation.mutateAsync(data);
      setOpenBulletinForm(false);
      navigate(`/bulletin/${response.data.id}`);
    } catch (error) {
      console.error("Error creating bulletin:", error);
    }
  };

  const handleUpdateBulletin = async (data) => {
    try {
      const response = await updateBulletinMutation.mutateAsync({
        id: currentBulletinId,
        data: data,
      });
      setSelectedBulletin(response.data);
      setEditingBulletin(false);
    } catch (error) {
      console.error("Error updating bulletin:", error);
    }
  };

  const handleCreatePost = async (data) => {
    try {
      await createPostMutation.mutateAsync(data);
      setOpenPostForm(false);
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const handleMembershipChange = async (memberId, memberType, isChecked) => {
    try {
      if (isChecked) {
        await addBulletinMemberMutation.mutateAsync({
          id: currentBulletinId,
          memberId: memberId,
          memberType: memberType
        });
      } else {
        setMemberToRemove({ id: memberId, type: memberType });
        setOpenMemberDialog(false);
        setOpenRemoveDialog(true);
      }
    } catch (error) {
      console.error("Error updating membership:", error);
    }
  };

  const handleRemoveMember = async () => {
    try {
      await removeBulletinMemberMutation.mutateAsync({
        id: currentBulletinId,
        memberId: memberToRemove.id,
        memberType: memberToRemove.type
      });
      setOpenRemoveDialog(false);
      setMemberToRemove(null);
      setOpenMemberDialog(true);
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };

  const bulletinFields = [
    { name: 'name', label: 'Bulletin Name', type: 'text', required: true },
  ];

  const postFields = [
    { name: 'title', label: 'Post Title', type: 'text', required: true },
    { name: 'content', label: 'Post Content', type: 'text', required: true, multiline: true, rows: 4 },
  ];

  const actions = [{ label: '+', action: () => setOpenBulletinForm(true) }];

  const tabs = bulletins.map(bulletin => ({
    label: bulletin.name,
    path: bulletin.id ? bulletin.id.toString() : '',
    action: () => setSelectedBulletin(bulletin)
  }));

  const memberCount = selectedBulletin ? selectedBulletin.coaches.length + selectedBulletin.athletes.length : 0;

  return (
    <PageWrapper 
      title="Bulletin Board" 
      tabs={tabs} 
      actions={actions}
      rootUrl="/bulletin"
    >
      <ComponentWrapper sx={{ m: 1, mt: 4, p: 3 }}>
        <Box sx={{ maxWidth: 800, margin: 'auto' }}>
          {isBulletinsLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
              <CircularProgress />
            </Box>
          ) : bulletinsError ? (
            <Typography color="error">Error loading bulletins: {bulletinsError.message}</Typography>
          ) : currentBulletinId ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Box 
                display="flex" 
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between" 
                mb={2}
              >
                <Box display="flex" alignItems="center">
                  <Chip 
                    label={selectedBulletin?.name?.toUpperCase()} 
                    sx={{ mr: 1 }} 
                    onDelete={userType === 'coach' ? () => setEditingBulletin(true) : undefined}
                    deleteIcon={<EditIcon />}
                  />
                  <Button 
                    variant="contained" 
                    color="primary" 
                    size="small"
                    onClick={() => setOpenPostForm(true)} 
                    sx={{ mr: 1 }}
                  >
                    New Post
                  </Button>
                  {userType === 'coach' && (
                    <Badge badgeContent={memberCount} color="primary">
                      <IconButton onClick={() => setOpenMemberDialog(true)}>
                        <GroupIcon />
                      </IconButton>
                    </Badge>
                  )}
                </Box>
              </Box>
              <Form
                open={openPostForm}
                onClose={() => setOpenPostForm(false)}
                fields={postFields}
                onSubmit={handleCreatePost}
                title="Create Post"
              />
              <Form
                open={editingBulletin}
                onClose={() => setEditingBulletin(false)}
                fields={bulletinFields}
                onSubmit={handleUpdateBulletin}
                title="Edit Bulletin"
                defaultValues={selectedBulletin}
              />
              {isPostsLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                  <CircularProgress />
                </Box>
              ) : postsError ? (
                <Typography color="error">Error loading posts: {postsError.message}</Typography>
              ) : (
                <List>
                  {postsData && postsData.length > 0 ? (
                    postsData.map(post => (
                      <motion.div
                        key={post.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Post post={post} />
                      </motion.div>
                    ))
                  ) : (
                    <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                      No posts yet. Create your first post!
                    </Typography>
                  )}
                </List>
              )}
            </motion.div>
          ) : (
            <Typography variant="h6" gutterBottom>Select a bulletin or create a new one</Typography>
          )}
        </Box>
      </ComponentWrapper>
      <Form
        open={openBulletinForm}
        onClose={() => setOpenBulletinForm(false)}
        fields={bulletinFields}
        onSubmit={handleCreateBulletin}
        title="Create Bulletin"
      />
      <Dialog open={openMemberDialog} onClose={() => setOpenMemberDialog(false)}>
        <DialogTitle>Manage Members</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Coaches</Typography>
          <FormGroup>
            {coaches.map(coach => (
              <FormControlLabel
                key={coach.id}
                control={
                  <Checkbox
                    checked={selectedBulletin?.coaches.some(c => c.id === coach.id)}
                    onChange={(e) => handleMembershipChange(coach.id, 'coach', e.target.checked)}
                  />
                }
                label={`${coach.firstName} ${coach.lastName}`}
              />
            ))}
          </FormGroup>
          <Typography variant="h6">Athletes</Typography>
          <FormGroup>
            {athletes.map(athlete => (
              <FormControlLabel
                key={athlete.id}
                control={
                  <Checkbox
                    checked={selectedBulletin?.athletes.some(a => a.id === athlete.id)}
                    onChange={(e) => handleMembershipChange(athlete.id, 'athlete', e.target.checked)}
                  />
                }
                label={`${athlete.firstName} ${athlete.lastName}`}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMemberDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <AreYouSure
        isOpen={openRemoveDialog}
        handleClose={() => {
          setOpenRemoveDialog(false);
          setOpenMemberDialog(true);
        }}
        onConfirm={handleRemoveMember}
        itemName="this member"
      />
    </PageWrapper>
  );
};

export default Bulletin;
