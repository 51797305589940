import React, { useState, useContext, useMemo } from "react";
import {
  Typography,
  Grid,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useApiService from "../../../../api/apiService";
import FormAthlete from "../../../../components/pods/athletePod/_components/formAthlete";
import PaceChart from "../../../../components/pods/athletePod/_components/paceChart";
import RacePrediction from "../../../../components/pods/athletePod/_components/racePrediction";
import { TableAthlete } from "./";
import { ComponentWrapper } from "../../../../components/utils";
import { AuthContext } from "../../../../context/authContext";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";
import ImportFromTffrs from "./importFromTffrs";
import SyncTffrsPrs from "./syncTffrsPrs";

function AthleteSection() {
  const [openForm, setOpenForm] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [athleteToDelete, setAthleteToDelete] = useState(null);
  const [openPaceChartDialog, setOpenPaceChartDialog] = useState(false);
  const [openRacePredictionDialog, setOpenRacePredictionDialog] =
    useState(false);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openSyncDialog, setOpenSyncDialog] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const apiService = useApiService();
  const { userType } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: athletes, refetch: refetchAthletes } = apiService.useAthletes();
  const { mutate: deleteAthlete } = apiService.useDeleteAthlete();
  const { mutate: createAthlete } = apiService.useCreateAthlete();
  const { mutate: updateAthlete } = apiService.useUpdateAthlete();

  const ATHLETE_TYPES = [
    "distance",
    "sprints",
    "throws",
    "jumps",
    "pole_vault",
    "multi"
  ];

  const filteredAthletes = useMemo(() => {
    return (
      athletes?.filter(
        (athlete) =>
          `${athlete.firstName} ${athlete.lastName}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) &&
          (selectedTypes.length === 0 ||
            athlete.athleteTypes.some((type) => selectedTypes.includes(type)))
      ) || []
    );
  }, [athletes, searchTerm, selectedTypes]);

  const handleAthleteCreated = (newAthlete) => {
    createAthlete(newAthlete, {
      onSuccess: () => {
        refetchAthletes();
      },
    });
  };

  const handleAthleteUpdated = (updatedAthlete) => {
    updateAthlete(
      { id: updatedAthlete.id, data: updatedAthlete },
      {
        onSuccess: () => {
          refetchAthletes();
        },
      }
    );
  };

  const handleDeleteAthlete = async () => {
    deleteAthlete(athleteToDelete.id, {
      onSuccess: () => {
        refetchAthletes();
        setOpenDeleteDialog(false);
        setAthleteToDelete(null);
      },
    });
  };

  const handleOpenForm = async (athlete = null) => {
    if (athlete) {
      setSelectedAthlete(athlete);
    } else {
      setSelectedAthlete(null);
    }
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleMenuClick = (event, athlete) => {
    setAnchorEl(event.currentTarget);
    setSelectedAthlete(athlete);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAthlete(null);
  };

  const handleDeleteClick = (athlete) => {
    setAthleteToDelete(athlete);
    setOpenDeleteDialog(true);
    handleMenuClose();
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setAthleteToDelete(null);
  };

  const handleOpenPaceChartDialog = (athlete) => {
    setOpenPaceChartDialog(true);
  };

  const handleOpenRacePredictionDialog = (athlete) => {
    setOpenRacePredictionDialog(true);
  };

  const handlePaceChartDialogClose = () => {
    setOpenPaceChartDialog(false);
  };

  const handleRacePredictionDialogClose = () => {
    setOpenRacePredictionDialog(false);
  };

  const handleOptionsClick = (event) => {
    setOptionsAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setOptionsAnchorEl(null);
  };

  const handleImportFromTffrs = () => {
    setOpenImportDialog(true);
    handleOptionsClose();
  };

  const handleSyncTffrsPrs = () => {
    setOpenSyncDialog(true);
    handleOptionsClose();
  };

  const handleTypeChange = (event, newTypes) => {
    setSelectedTypes(newTypes);
  };

  return (
    <ComponentWrapper>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box p={2} display="flex" flexDirection="column" alignItems="stretch">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Search Athletes"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ flexGrow: 1, marginRight: "10px" }}
              />
              {userType === "coach" && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleOpenForm()}
                  sx={{
                    marginRight: "10px",
                    ...(isMobile && { fontSize: "0.5rem" }),
                  }}
                >
                  Add Athlete
                </Button>
              )}
              {userType === "coach" && (
                <IconButton color="primary" onClick={handleOptionsClick}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
            {isMobile ? (
              <FormControl
                size="small"
                fullWidth
                style={{ marginBottom: "10px" }}
              >
                <InputLabel id="athlete-type-label">Athlete Types</InputLabel>
                <Select
                  labelId="athlete-type-label"
                  multiple
                  value={selectedTypes}
                  onChange={(event) =>
                    handleTypeChange(event, event.target.value)
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} size="small" />
                      ))}
                    </Box>
                  )}
                >
                  {ATHLETE_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <ToggleButtonGroup
                value={selectedTypes}
                onChange={handleTypeChange}
                aria-label="athlete types"
                size="small"
                sx={{ marginBottom: "10px" }}
              >
                {ATHLETE_TYPES.map((type) => (
                  <ToggleButton key={type} value={type} aria-label={type}>
                    {type}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          </Box>
          <TableAthlete
            athletes={filteredAthletes}
            handleMenuClick={handleMenuClick}
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            handleOpenForm={userType === "coach" ? handleOpenForm : null}
            handleDeleteClick={userType === "coach" ? handleDeleteClick : null}
            handleOpenPaceChartDialog={handleOpenPaceChartDialog}
            handleOpenRacePredictionDialog={handleOpenRacePredictionDialog}
            selectedAthlete={selectedAthlete}
          />
        </Grid>
      </Grid>
      {userType === "coach" && (
        <FormAthlete
          open={openForm}
          handleClose={handleCloseForm}
          onAthleteCreated={handleAthleteCreated}
          onAthleteUpdated={handleAthleteUpdated}
          athlete={selectedAthlete}
        />
      )}
      <AreYouSure
        isOpen={openDeleteDialog}
        handleClose={handleDeleteDialogClose}
        onConfirm={handleDeleteAthlete}
        itemName="athlete"
      />
      <Dialog
        open={openPaceChartDialog}
        onClose={handlePaceChartDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedAthlete ? (
            <PaceChart athlete={selectedAthlete} />
          ) : (
            <Typography>No athlete selected</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePaceChartDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRacePredictionDialog}
        onClose={handleRacePredictionDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedAthlete ? (
            <RacePrediction athlete={selectedAthlete} />
          ) : (
            <Typography>No athlete selected</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRacePredictionDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={optionsAnchorEl}
        open={Boolean(optionsAnchorEl)}
        onClose={handleOptionsClose}
      >
        <MenuItem onClick={handleImportFromTffrs}>
          Import Athletes from TFFRS
        </MenuItem>
        <MenuItem onClick={handleSyncTffrsPrs}>
          Sync TFFRS PRs
        </MenuItem>
        <MenuItem disabled>Increment all years by 1</MenuItem>
        <MenuItem disabled>Decrement all years by 1</MenuItem>
      </Menu>
      <ImportFromTffrs
        open={openImportDialog}
        handleClose={() => setOpenImportDialog(false)}
      />
      <Dialog
        open={openSyncDialog}
        onClose={() => setOpenSyncDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <SyncTffrsPrs athletes={athletes} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSyncDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ComponentWrapper>
  );
}

export { AthleteSection };
