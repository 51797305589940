import React from "react";
import Form from "../../../../../components/form/form";

const WorkoutForm = ({
  open,
  handleClose,
  selectedWorkout,
  handleSaveWorkout,
}) => {
  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      required: true,
    },
    {
      name: "description",
      label: "Description",
      type: "text",
    },
    {
      name: "workout_type",
      label: "Workout Type",
      type: "text",
      required: true,
    },
  ];

  return (
    <Form
      open={open}
      onClose={handleClose}
      fields={fields}
      onSubmit={handleSaveWorkout}
      title={selectedWorkout ? "Edit Workout" : "Create Workout"}
      defaultValues={selectedWorkout || {}}
    />
  );
};

export { WorkoutForm };
