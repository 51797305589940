import React from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Stack,
} from "@mui/material";
import {
  LibraryBooks,
  People,
  Timeline,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import WelcomeFeature from "./welcomeFeature";

const Welcome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      sx={{
        backgroundColor: theme.palette.background.paperSecondary,
        borderRadius: 3,
        padding: { xs: 2, sm: 4, md: 6 },
        boxShadow: theme.shadows[6],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
      }}
    >
      <Typography
        variant={isMobile ? "h5" : "h3"}
        gutterBottom
        fontWeight="700"
        color="primary"
        component={motion.div}
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" } }}
      >
        Streamline Your Coaching Journey
      </Typography>
      <Typography
        variant={isMobile ? "subtitle1" : "h5"}
        gutterBottom
        color="text.secondary"
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.6 }}
        sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } }}
      >
        Save time and enhance training effectiveness with Track Tools Pro
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 4 }}
        my={4}
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        component={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        whileInView={{ scale: 1, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: 0.5 }}
        sx={{ width: "100%" }}
      >
        <WelcomeFeature
          icon={<LibraryBooks fontSize="large" />}
          title="Reusable Workout Library"
          description="Easily collect, modify, and reuse your workouts for efficient training plan creation."
        />
        <WelcomeFeature
          icon={<People fontSize="large" />}
          title="Efficient Roster Management"
          description="Effortlessly import your athletes from TFFRS using the 'More Options' button on the roster page."
        />
        <WelcomeFeature
          icon={<Timeline fontSize="large" />}
          title="Comprehensive Training Log"
          description="Log your training sessions or have your athletes do it themselves, enabling efficient progress tracking over time."
          comingSoon={true}
        />
      </Stack>
      <Button
        variant="contained"
        size="large"
        color="primary"
        sx={{
          fontWeight: "700",
          px: { xs: 3, sm: 5 },
          py: { xs: 1.5, sm: 1.8 },
          borderRadius: 2,
          width: { xs: "100%", sm: "auto" },
        }}
        component={motion.button}
        whileHover={{ scale: 1.05 }}
        onClick={() => navigate("/login/create-account")}
      >
        Get Started Now
      </Button>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          cursor: "pointer",
          color: "primary.main",
          textDecoration: "underline",
        }}
        component={motion.p}
        whileHover={{ textDecoration: "none", color: "primary.dark" }}
        onClick={() => navigate("/login")}
      >
        Already a member? Log In
      </Typography>
    </Box>
  );
};

export { Welcome };