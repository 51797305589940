import React, { useState, useContext, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useApiService from "../../api/apiService";
import { AuthContext } from "../../context/authContext";
import Confetti from "react-confetti";
import ComponentWrapper from "../utils/wrappers/componentWrapper";

const SubscriptionForm = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const [pollingSubscription, setPollingSubscription] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { createSubscription, useTeam } = useApiService();
  const { updateTeam, team } = useContext(AuthContext);
  const { data: teamData, refetch } = useTeam(team?.id);

  const steps = ["Select Plan", "Payment Details", "Confirmation"];

  const plans = [
    {
      id: "plan_free",
      name: "Free Plan",
      description: "Perfect for Getting Started",
      price: "Free",
      features: [
        { icon: <FitnessCenterIcon />, text: "Workout Generation" },
        { icon: <PeopleIcon />, text: "10 Athlete Profiles" },
        { icon: <PersonIcon />, text: "1 Coach Profile" },
        { icon: <EventIcon />, text: "Training Plan" },
        { icon: <EventIcon />, text: "Event Groups" },
      ],
      icon: <CheckCircleOutlineIcon color="primary" fontSize="large" />,
    },
    {
      id: isYearly ? "plan_pro_yearly" : "plan_pro_monthly",
      name: "Pro Plan",
      description: "Ideal for professionals",
      price: isYearly ? "$250/Year" : "$25/Month",
      features: [
        { icon: <FitnessCenterIcon />, text: "All Free Plan Features" },
        { icon: <PeopleIcon />, text: "Unlimited Athlete Profiles" },
        { icon: <PersonIcon />, text: "Unlimited Coach Profiles" },
        { icon: <EventIcon />, text: "Training Log (coming soon)" },
      ],
      icon: <StarIcon color="primary" fontSize="large" />,
    },
  ];

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setSelectedPlan(null);
      setError(null);
    }
  }, [open]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Stripe has not been initialized");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError("Card element not found");
      setLoading(false);
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        throw new Error(
          error.message || "An error occurred with the payment method"
        );
      }

      const response = await createSubscription({
        payment_method_id: paymentMethod.id,
        plan: selectedPlan.id,
      });

      if (response.data.status === "success") {
        console.log("Subscription created:", response.data.subscription);
        handleSuccess(response.data.subscription);
      } else {
        throw new Error(response.data.message || "An error occurred");
      }
    } catch (err) {
      console.error("Subscription error:", err);
      setError(err.message || "Failed to create subscription");
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = async (initialSubscriptionData) => {
    setShowConfetti(true);
    setActiveStep(2);
    setPollingSubscription(true);

    // Immediately update with initial data
    updateTeam({
      ...teamData,
      subscription: initialSubscriptionData
    });

    let attempts = 0;
    const maxAttempts = 6;
    const pollInterval = 5000; // 5 seconds

    const pollSubscription = async () => {
      while (attempts < maxAttempts) {
        try {
          const { data } = await refetch();
          if (
            data?.subscription &&
            data.subscription.status === 'active' &&
            new Date(data.subscription.currentPeriodEnd) > new Date()
          ) {
            updateTeam(data);
            setPollingSubscription(false);
            setShowConfetti(false);
            // onClose();
            return;
          }
        } catch (err) {
          console.error("Error fetching team data:", err);
        }
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, pollInterval));
      }

      console.warn("Subscription status not updated after polling. Using initial data.");
      setPollingSubscription(false);
      setShowConfetti(false);
      // onClose();
    };

    pollSubscription();
  };

  const handleCardChange = (event) => {
    setCardComplete(event.complete);
  };

  const handleSelectPlan = (plan) => {
    if (plan.id !== "plan_free") {
      setSelectedPlan(plan);
      setActiveStep(1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleToggleChange = (event) => {
    setIsYearly(event.target.checked);
  };

  const getCurrentPlan = () => {
    if (team?.subscription?.plan === "plan_pro_yearly") {
      return "Pro Plan";
    } else if (team?.subscription?.plan === "plan_pro_monthly") {
      return "Pro Plan";
    } else if (team?.subscription?.plan === "plan_free") {
      return "Free Plan";
    } else {
      return "Unknown Plan";
    }
  };

  const hasActiveSubscription = team?.subscription && team.subscription.status !== 'past_due';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {hasActiveSubscription
          ? "Your Current Subscription"
          : "Choose Your Subscription Plan"}
      </DialogTitle>
      <DialogContent>
        {hasActiveSubscription ? (
          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              Your current plan: {getCurrentPlan()}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Subscription Status: {team.subscription.status}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Current Period End:{" "}
              {new Date(
                team.subscription.currentPeriodEnd
              ).toLocaleDateString()}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              To change or cancel your plan, please contact support. We're
              working on making this process easier in future updates.
              steven@trackcoachpro.com
            </Typography>
          </Box>
        ) : (
          <>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <Grid
                container
                spacing={3}
                sx={{ mt: 2 }}
                justifyContent="center"
              >
                {plans.map((plan) => (
                  <Grid item xs={12} md={4} key={plan.id}>
                    <ComponentWrapper>
                      <Card
                        variant="outlined"
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          border:
                            selectedPlan?.id === plan.id
                              ? "2px solid #1976d2"
                              : "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                          {plan.icon}
                          <Typography variant="h5" component="div" gutterBottom>
                            {plan.name}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            gutterBottom
                          >
                            {plan.description}
                          </Typography>
                          <Typography variant="h6" color="primary" gutterBottom>
                            {plan.price}
                          </Typography>
                          <Box component="ul" sx={{ pl: 2 }}>
                            {plan.features.map((feature, index) => (
                              <Box
                                component="li"
                                key={index}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mb: 1,
                                }}
                              >
                                {feature.icon}
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                  {feature.text}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </CardContent>
                        <CardActions>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={() => handleSelectPlan(plan)}
                            disabled={plan.id === "plan_free"}
                          >
                            {plan.id === "plan_free" ? "Current Plan" : "Select Plan"}
                          </Button>
                        </CardActions>
                      </Card>
                    </ComponentWrapper>
                  </Grid>
                ))}
              </Grid>
            )}
            {activeStep === 0 && (
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <FormControlLabel
                  control={
                    <Switch checked={isYearly} onChange={handleToggleChange} />
                  }
                  label="Yearly Billing"
                />
              </Box>
            )}
            {activeStep === 1 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Selected Plan: {selectedPlan.name}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Price: {selectedPlan.price}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Payment Details
                </Typography>
                <CardElement onChange={handleCardChange} />
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                  Secure payment processed by Stripe
                </Typography>
              </Box>
            )}
            {activeStep === 2 && (
              <Box sx={{ mt: 4, textAlign: "center" }}>
                {pollingSubscription ? (
                  <>
                    <CircularProgress />
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      Confirming your subscription...
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h5" gutterBottom>
                      Thank you for your subscription!
                    </Typography>
                    <Typography variant="subtitle1">
                      Your plan is now active.
                    </Typography>
                  </>
                )}
                {showConfetti && <Confetti />}
              </Box>
            )}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!hasActiveSubscription && activeStep > 0 && activeStep < 2 && (
          <Button onClick={handleBack}>Back</Button>
        )}
        {!hasActiveSubscription && activeStep === 1 && (
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !cardComplete}
            onClick={handleSubmit}
          >
            {loading ? "Processing..." : "Subscribe"}
          </Button>
        )}
        {(hasActiveSubscription || (activeStep === 2 && !pollingSubscription)) && (
          <Button onClick={onClose}>Close</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionForm;
