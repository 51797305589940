import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Container,
  Grid,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { motion, AnimatePresence } from "framer-motion";
import { WordPullUp } from "../../../../components/magicUI/wordPullUp/wordPullUp";

const EventGroups = `https://imgur.com/oi6gDWD.png`;
const Roster = `https://imgur.com/MvEEzO1.png`;
const TrainingPlans = `https://imgur.com/W7fzowg.png`;
const Workouts = `https://imgur.com/BkYfwGv.png`;

const features = [
  {
    image: Roster,
    title: "Athlete Management",
    description: "Streamline your team organization with our powerful athlete management tools.",
    bullets: [
      "Effortlessly manage team rosters",
      "Seamless TFFRS integration",
      "Dynamic workout calculations tailored to each athlete",
    ],
  },
  {
    image: EventGroups,
    title: "Event Groups",
    description: "Optimize your training strategy with specialized event-based organization.",
    bullets: [
      "Organize athletes by event groups",
      "Tailor training plans for specific events",
      "Maximize team performance through strategic grouping",
    ],
  },
  {
    image: Workouts,
    title: "Workout Planning",
    description: "Create and customize workouts with ease using our intuitive planning tools.",
    bullets: [
      "Design custom workout templates",
      "Automatically calculate paces for each athlete",
      "Categorize workouts for easy reference and planning",
    ],
  },
  {
    image: TrainingPlans,
    title: "Training Plans",
    description: "Develop comprehensive training plans that adapt to your athletes' needs.",
    bullets: [
      "Edit plans on-the-fly for maximum flexibility",
      "Auto-updating workouts based on athlete progress",
      "Allow athletes to view and track their personalized plans",
    ],
  },
];

const ExploreFeatures = () => {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    features.forEach((feature) => {
      const img = new Image();
      img.src = feature.image;
      img.onload = () => {
        setLoadedImages((prev) => ({ ...prev, [feature.image]: true }));
      };
    });
  }, []);

  const handlePrev = () => {
    setCurrentFeature((prev) => (prev === 0 ? features.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentFeature((prev) => (prev === features.length - 1 ? 0 : prev + 1));
  };

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  return (
    <Container maxWidth="lg" sx={{ py: isMobile ? 0 : 0 }}>
      <WordPullUp
        words="Explore Our Powerful Features"
        className="mb-6"
      />
      <Box sx={{ position: "relative", overflow: "hidden", minHeight: isMobile ? "400px" : "300px" }}>
        <IconButton
          onClick={handlePrev}
          sx={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            bgcolor: "background.paper",
            boxShadow: 2,
            "&:hover": { bgcolor: "background.paper" },
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <AnimatePresence initial={false} custom={currentFeature}>
          <motion.div
            key={currentFeature}
            custom={currentFeature}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid container spacing={isMobile ? 0 : 0} alignItems="center" direction={isMobile ? "column" : "row"}>
              <Grid item xs={12} md={6}>
                {loadedImages[features[currentFeature].image] ? (
                  <motion.img
                    src={features[currentFeature].image}
                    alt={features[currentFeature].title}
                    style={{ width: "100%", maxHeight: isMobile ? "200px" : "400px", objectFit: "contain" }}
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    // transition={{ duration: 0.5 }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      height: isMobile ? "200px" : "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "grey.100",
                    }}
                  >
                    <Typography>Loading image...</Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" sx={{ mb: 2, fontWeight: "bold", color: theme.palette.primary.main, fontSize: isMobile ? "1.5rem" : "2rem" }}>
                  {features[currentFeature].title}
                </Typography>
                <Typography variant="h6" sx={{ mb: 4, color: theme.palette.text.secondary, fontSize: isMobile ? "1rem" : "1.25rem" }}>
                  {features[currentFeature].description}
                </Typography>
                {!isMobile && features[currentFeature].bullets.map((bullet, idx) => (
                  <motion.div
                    key={idx}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: idx * 0.1 }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 2,
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        color: theme.palette.text.primary,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          bgcolor: theme.palette.primary.main,
                          display: "inline-block",
                          mr: 2,
                        }}
                      />
                      {bullet}
                    </Typography>
                  </motion.div>
                ))}
              </Grid>
            </Grid>
          </motion.div>
        </AnimatePresence>
        <IconButton
          onClick={handleNext}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            bgcolor: "background.paper",
            boxShadow: 2,
            "&:hover": { bgcolor: "background.paper" },
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Container>
  );
};

export { ExploreFeatures };
