import {
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PageWrapper from "../pageWrapper/pageWrapper";
import { useContext, useRef, useState, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import {
  ComingSoon,
  CreateOrLogin,
  Counts,
  ExploreFeatures,
  UserCounts,
  Welcome,
  DownloadPWA,
  TodaysWorkouts,
} from "./_components";
import SubTiers from "./_components/subTiers";
import { motion, AnimatePresence } from "framer-motion";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import ExploreIcon from "@mui/icons-material/Explore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import DownloadIcon from "@mui/icons-material/Download";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { CheckGoals } from "./_components/checkGoals/checkGoals";
import { TffrsSection } from "./_components/tffrs/tffrs";
import { Motivation } from "./_components/motivation";

function Home() {
  const { token } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [activeSection, setActiveSection] = useState(0);
  const sectionRefs = useRef([]);

  const sections = !token
    ? [
        { component: Welcome, icon: <HomeIcon /> },
        { component: Counts, icon: <PeopleIcon /> },
        { component: ExploreFeatures, icon: <ExploreIcon /> },
        { component: CreateOrLogin, icon: <LoginIcon /> },
        { component: TffrsSection, icon: <CloudSyncIcon /> },
        { component: SubTiers, icon: <WorkspacePremiumIcon /> },
        { component: ComingSoon, icon: <NewReleasesIcon /> },
      ]
    : [
        { component: Motivation, icon: <HomeIcon /> },
        { component: TodaysWorkouts, icon: <TaskAltIcon /> },
        { component: CheckGoals, icon: <TaskAltIcon /> },
        { component: UserCounts, icon: <AccountCircleIcon /> },
        { component: DownloadPWA, icon: <DownloadIcon /> },
        { component: ComingSoon, icon: <NewReleasesIcon /> },
        { component: SubTiers, icon: <WorkspacePremiumIcon /> },
      ];

  useEffect(() => {
    if (!isMobile) {
      const handleScroll = () => {
        const pageMiddle = window.pageYOffset + window.innerHeight / 2;
        let closestSection = 0;
        let minDistance = Infinity;

        sectionRefs.current.forEach((ref, index) => {
          if (ref) {
            const sectionMiddle = ref.offsetTop + ref.offsetHeight / 2;
            const distance = Math.abs(pageMiddle - sectionMiddle);
            if (distance < minDistance) {
              minDistance = distance;
              closestSection = index;
            }
          }
        });

        setActiveSection(closestSection);
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isMobile]);

  const scrollToSection = (index) => {
    const sectionElement = sectionRefs.current[index];
    if (sectionElement) {
      const sectionTop = sectionElement.offsetTop;
      const sectionHeight = sectionElement.offsetHeight;
      const windowHeight = window.innerHeight;
      const scrollTo = sectionTop - windowHeight / 2 + sectionHeight / 2;

      window.scrollTo({
        top: scrollTo,
        behavior: "smooth",
      });
    }
  };

  const tabs = [
  ];

  return (
    <PageWrapper
      rootUrl="/home"
      sx={{ mt: !token && !isMobile ? 0 : !isMobile ? -10 : 3 }}
      tabs={tabs}
      ttp={true}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          padding: isMobile ? 1 : 10,
        }}
      >
        {!isMobile && (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.5 }}
              style={{
                position: "fixed",
                left: 20,
                top: "50%",
                transform: "translateY(-50%)",
                display: "flex",
                flexDirection: "column",
                gap: 12,
                zIndex: 1000,
              }}
            >
              {sections.map(({ icon }, index) => (
                <motion.div
                  key={index}
                  onClick={() => scrollToSection(index)}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    backgroundColor:
                      activeSection === index
                        ? theme.palette.primary.main
                        : theme.palette.mode === 'light'
                          ? theme.palette.grey[300]
                          : theme.palette.grey[700],
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color:
                      activeSection === index
                        ? theme.palette.primary.contrastText
                        : theme.palette.text.primary,
                  }}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  animate={{
                    scale: activeSection === index ? 1.1 : 1,
                    boxShadow:
                      activeSection === index
                        ? `0px 0px 8px ${theme.palette.mode === 'light' ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.3)'}`
                        : "none",
                  }}
                >
                  {icon}
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: isMobile ? 5 : 10,
            flexGrow: 1,
          }}
        >
          {sections.map(({ component: Section }, index) => (
            <Box key={index} ref={(el) => (sectionRefs.current[index] = el)}>
              <Section />
            </Box>
          ))}
        </Box>
      </Box>
    </PageWrapper>
  );
}

export default Home;
