import React, { useState } from "react";
import { Box, Typography, IconButton, Chip } from "@mui/material";
import { motion } from "framer-motion";

const WelcomeFeature = ({ icon, title, description, comingSoon = false }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Box
      component={motion.div}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleFlip}
      sx={{
        width: { xs: "100%", sm: 250 },
        height: { xs: 100, sm: 250 },
        perspective: "1000px",
        cursor: "pointer",
        WebkitTapHighlightColor: "transparent",
        userSelect: "none",
        mb: { xs: 2, sm: 0 },
      }}
    >
      <motion.div
        animate={{ rotateY: isFlipped ? 180 : 0 }}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          transformStyle: "preserve-3d",
        }}
      >
        {/* Front of the card */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 3,
            p: 2,
          }}
        >
          {comingSoon && (
            <Chip
              label="Coming Soon"
              color="primary"
              size="small"
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            />
          )}
          <IconButton color="primary" sx={{ mb: 2 }}>
            {icon}
          </IconButton>
          <Typography variant="h6" align="center" sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}>
            {title}
          </Typography>
        </Box>

        {/* Back of the card */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 3,
            p: 2,
            transform: "rotateY(180deg)",
          }}
        >
          <Typography variant="body2" align="center" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
            {description}
          </Typography>
        </Box>
      </motion.div>
    </Box>
  );
};

export default WelcomeFeature;
