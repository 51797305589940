import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  IconButton,
  Box,
  Paper,
  Collapse,
  Menu,
  MenuItem,
  Chip,
  TextField,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import useApiService from "../../../../api/apiService";
import CollapseIcon from "../../../../components/utils/collapseIcon/collapseIcons";
import WorkoutSetPod from "./workoutSetPod";
import { ComponentWrapper } from "../../../../components/utils/wrappers";
import MenuIcon from "../../../../components/utils/menuIcon";
import { WorkoutForm } from "./forms/workoutForm";
import AreYouSure from "../../../../components/utils/areYouSure/areYouSure";

const WorkoutsSection = () => {
  const { useWorkouts, useCreateWorkout, useUpdateWorkout, useDeleteWorkout } =
    useApiService();
  const { data: workouts, isLoading, isError, refetch } = useWorkouts();
  const [openWorkoutDialog, setOpenWorkoutDialog] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [expandedWorkoutId, setExpandedWorkoutId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [filterType, setFilterType] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [workoutTypes, setWorkoutTypes] = useState([]);

  const createWorkoutMutation = useCreateWorkout();
  const updateWorkoutMutation = useUpdateWorkout();
  const deleteWorkoutMutation = useDeleteWorkout();

  useEffect(() => {
    if (workouts && workouts.length > 0) {
      setExpandedWorkoutId(workouts[0].id);
      const types = [...new Set(workouts.map(workout => workout.workoutType))];
      setWorkoutTypes(types);
    }
  }, [workouts]);

  const handleClickOpenWorkout = (workout) => {
    setSelectedWorkout(workout);
    setOpenWorkoutDialog(true);
    setAnchorEl(null);
  };

  const handleCloseWorkoutDialog = () => {
    setOpenWorkoutDialog(false);
    setSelectedWorkout(null);
  };

  const handleSaveWorkout = async (workoutData) => {
    try {
      if (selectedWorkout) {
        await updateWorkoutMutation.mutateAsync({
          id: selectedWorkout.id,
          data: workoutData,
        });
      } else {
        await createWorkoutMutation.mutateAsync(workoutData);
      }
      handleCloseWorkoutDialog();
      refetch(); // Refetch workouts after saving
    } catch (error) {
      console.error("Error saving workout:", error);
    }
  };

  const handleDeleteWorkout = async () => {
    try {
      await deleteWorkoutMutation.mutateAsync(selectedWorkout.id);
      handleMenuClose();
    } catch (error) {
      console.error("Error deleting workout:", error);
    }
  };

  const handleExpandClick = (workoutId) => {
    setExpandedWorkoutId(expandedWorkoutId === workoutId ? null : workoutId);
  };

  const handleMenuClick = (event, workout) => {
    event.stopPropagation();
    setSelectedWorkout(workout);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterNameChange = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const filteredWorkouts = workouts
    ? workouts.filter(
        (workout) =>
          (filterName === "" ||
            workout.name.toLowerCase().includes(filterName.toLowerCase())) &&
          (filterType === "" || workout.workoutType === filterType)
      )
    : [];

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching workouts</div>;

  return (
    <ComponentWrapper sx={{ p: 2 }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Workouts
        <IconButton onClick={() => handleClickOpenWorkout(null)}>
          <AddIcon />
        </IconButton>
      </Typography>
      <Box display="flex" flexDirection="row" mb={2}>
        <TextField
          label="Filter by Name"
          variant="outlined"
          value={filterName}
          onChange={handleFilterNameChange}
          sx={{ mr: 2 }}
        />
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel id="filter-type-label">Filter by Type</InputLabel>
          <Select
            labelId="filter-type-label"
            value={filterType}
            onChange={handleFilterTypeChange}
            label="Filter by Type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {workoutTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <List>
        {filteredWorkouts.map((workout) => (
          <Paper
            key={workout.id}
            elevation={0}
            sx={{
              borderRadius: 4,
              overflow: "hidden",
              mb: 2,
              border: (theme) => `1px solid ${theme.palette.border.main}`,
            }}
          >
            <ListItem
              button
              onClick={() => handleExpandClick(workout.id)}
            >
              <MenuIcon onClick={(e) => handleMenuClick(e, workout)} />
              <Box display="flex" flexDirection="row" width="100%">
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">{workout.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {workout.description}
                  </Typography>
                </Box>
              </Box>
              <Chip size="small" label={workout.workoutType} />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleExpandClick(workout.id);
                }}
              >
                <CollapseIcon expanded={expandedWorkoutId === workout.id} />
              </IconButton>
            </ListItem>
            <Collapse
              in={expandedWorkoutId === workout.id}
              timeout="auto"
              unmountOnExit
            >
              <Box sx={{ p: 2 }}>
                <WorkoutSetPod workoutId={workout.id} />
              </Box>
            </Collapse>
          </Paper>
        ))}
      </List>
      {openWorkoutDialog && (
        <WorkoutForm
          open={openWorkoutDialog}
          handleClose={handleCloseWorkoutDialog}
          selectedWorkout={selectedWorkout}
          handleSaveWorkout={handleSaveWorkout}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleClickOpenWorkout(selectedWorkout)}>
          <EditIcon fontSize="small" style={{ marginRight: "8px" }} />
          Edit
        </MenuItem>
        <MenuItem onClick={() => {
          setIsDeleteDialogOpen(true);
          handleMenuClose();
        }}>
          <DeleteIcon fontSize="small" style={{ marginRight: "8px" }} />
          Delete
        </MenuItem>
      </Menu>
      <AreYouSure
        isOpen={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteWorkout}
        itemName={selectedWorkout?.name || "this workout"}
      />
    </ComponentWrapper>
  );
};

export default WorkoutsSection;
