import React, { useEffect, useState } from 'react';
import './App.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//components
import SideNav from './components/sideNav/sideNav';
import { useContext } from 'react';
import { AuthContext } from './context/authContext';
import { ThemeProvider } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import createAppTheme from './theme';
import { Toaster } from 'sonner';
import Island from './context/island/island';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const { token } = useContext(AuthContext);
  const [theme, setTheme] = useState(createAppTheme());

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setTheme(createAppTheme(e.matches ? 'dark' : 'light'));
    };

    handleChange(mediaQuery);
    mediaQuery.addListener(handleChange);

    return () => mediaQuery.removeListener(handleChange);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <div className="App">
          {token && <SideNav />}
          <Island />
          <Toaster 
            position="top-right" 
            toastOptions={{ 
              duration: 750,
              success: {
                style: {
                  border: '1px solid green',
                },
              },
            }} 
          />
          <Outlet />
        </div>
      </Elements>
    </ThemeProvider>
  );
}

export default App;
